import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Input,
  Popconfirm,
  Tooltip,
  Select,
  Modal,
  Table,
} from "antd";
import "./task-detail.css";
import { useNavigate, useParams } from "react-router-dom";
import InfoCard from "../components/UserDetail/InfoCard";
import SalesCard from "../components/UserDetail/SalesCard";
import CustomerInfo from "../components/UserDetail/CustomerInfo";
import CustomerService from "../services/CustomerService";
import phoneIcon from "../assets/phone.svg";
import smsIcon from "../assets/sms.svg";
import Notes from "../components/UserDetail/Notes";
import LearnerService from "../services/LearnerService";
import {
  PhoneOutlined,
  CloseCircleOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { openNotification } from "../utils";
import Tasks from "../components/UserDetail/Tasks/Tasks";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CopyClipboard } from "../components/General/CopyClipboard";
import CallHistory from "../components/UserDetail/CallHistory";
import CallTeamService from "../services/CallTeamService";
import moment from "moment";
import CallSalesPackageService from "../services/CallSalesPackageService";
import AdditionalPackageHistoryCard from "../components/CustomerDetail/AdditionalPackageHistoryCard";
import UserService from "../services/UserService";
import { FiPhone } from "react-icons/fi";
import StatusService from "../services/StatusService";

const { Item } = Form;

const UserDetail = () => {
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const { id } = useParams<{ id: string }>();
  const [customer, setCustomer] = useState<any>(null);
  const [form] = Form.useForm();
  const [gsmPortalEmployeeId, setGsmPortalEmployeeId] = useState("");
  const [customerStatusOptions, setCustomerStatusOptions] = useState<any[]>([]);
  const navigate = useNavigate();
  // const location = useLocation();
  // const currentPage = location.state?.currentPage ?? 0;
  // const currentPageSize = location.state?.currentPageSize ?? 0;
  // const currentFilterText = location.state?.filterText ?? "";
  const [learnerDetail, setLearnerDetail] = useState();
  const [callInfo, setCallInfo] = useState<any>();
  const [isCustomer, setIsCustomer] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [options, setOptions] = useState([]);
  const [salesLog, setSalesLog] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [packageNames, setPackageNames] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [userOptions, setUserOptions] = useState<any>([]);
  const roleName = localStorage.getItem("roleName");

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line
  }, [id, triggerUpdate]);

  const getCustomerStatus = () => {
    StatusService.getAll("statusType=CALL_CENTER_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let netGsmId = localStorage.getItem("gsmPortalEmployeeId");
    // eslint-disable-next-line
    if (netGsmId != null && netGsmId != "") {
      setGsmPortalEmployeeId(netGsmId);
    }
    getCustomerStatus();
    // eslint-disable-next-line
  }, []);

  const getCustomer = () => {
    CustomerService.get(id)
      .then((response: any) => {
        setCustomer(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
        LearnerService.get(id)
          .then((response: any) => {
            setCustomer(response.data);
            setIsCustomer(false);
            CustomerService.getLearnerDetail(id)
              .then((response: any) => {
                setLearnerDetail(response.data);
              })
              .catch((e: Error) => {
                console.log(e);
              });
          })
          .catch((e: Error) => {
            console.log(e);
          });
      });
  };

  useEffect(() => {
    if (customer) {
      CallTeamService.callCard(
        `?phoneNumber=${customer.phone ?? customer?.phoneNumber}`
      )
        .then((response: any) => {
          setCallInfo(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line
  }, [customer]);

  form.setFieldsValue({
    phoneNumber: customer?.phone,
  });

  const handeStartCall = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        openNotification("success", "Calling", values.phoneNumber, 5);
        LearnerService.learnerCall(gsmPortalEmployeeId, values.phoneNumber)
          .then((response: any) => {
            if (response.data?.status === "Success") {
              openNotification(
                "success",
                response.data.message,
                values.phoneNumber,
                5
              );
            } else {
              openNotification(
                "error",
                response.data.message,
                values.phoneNumber,
                5
              );
            }
          })
          .catch((e: Error) => {});
      }
    } catch (error) {
      openNotification(
        "error",
        "Start a call",
        "Phone number must comply with the format"
      );
    }
  };

  const callForm = () => {
    // eslint-disable-next-line
    if (
      // eslint-disable-next-line
      gsmPortalEmployeeId != "" &&
      // eslint-disable-next-line
      gsmPortalEmployeeId != null &&
      !isNaN(Number(gsmPortalEmployeeId)) &&
      // eslint-disable-next-line
      gsmPortalEmployeeId != "null" &&
      gsmPortalEmployeeId
    ) {
      return (
        <Popconfirm
          title="Customer the call"
          description={
            <Form form={form} layout="vertical">
              Are you sure you want to call the customer?
              <Item
                style={{ marginTop: "10px" }}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Phone number must be only digits!",
                  },
                  {
                    len: 11,
                    message: "Phone number must be 11 digits long!",
                  },
                ]}
              >
                <Input />
              </Item>
            </Form>
          }
          okText={
            <>
              <PhoneOutlined /> Call
            </>
          }
          cancelText="No"
          onConfirm={() => handeStartCall()}
        >
          <Button
            type="primary"
            style={{
              marginLeft: 16,
              fontSize: 10,
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
            }}
          >
            <FiPhone style={{ fontSize: "13px", marginRight: "5px" }} />
            Call
          </Button>
        </Popconfirm>
      );
    }

    return (
      <Tooltip
        placement="top"
        title={"Arama yapmak için yöneticiniz ile iletişime geçiniz"}
      >
        <Button
          type="primary"
          style={{
            marginLeft: 16,
            fontSize: 10,
            fontWeight: "bold",
            alignItems: "center",
            display: "flex",
          }}
        >
          <FiPhone style={{ fontSize: "13px", marginRight: "5px" }} />
          Call
        </Button>
      </Tooltip>
    );
  };
  // const getLevelColor = (level: number) => {
  //   switch (level) {
  //     case 0:
  //       return "red";
  //     case 1:
  //       return "volcano";
  //     case 2:
  //       return "orange";
  //     case 3:
  //       return "gold";
  //     case 4:
  //       return "lime";
  //     case 5:
  //       return "green";
  //     case 6:
  //       return "cyan";
  //     default:
  //       return "gray";
  //   }
  // };

  const handleChangeStatus = (status: any, id: any) => {
    // eslint-disable-next-line
    if (isCustomer == true) {
      CallTeamService.updateCustomerStatus(id, { callCenterStatusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed status");
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else {
      CallTeamService.updateLearnerStatus(id, { callCenterStatusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed status");
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  // const handleFeedback = () => {
  //   if (customer?.id) {
  //     let data: any = {
  //       id: customer?.id,
  //     };
  //     CustomerService.sendFeedbackRequest(data)
  //       .then((response: any) => {
  //         openNotification(
  //           "success",
  //           "Successs",
  //           "The feedback request was sent successfully"
  //         );
  //       })
  //       .catch((e: Error) => {
  //         console.log(e);
  //         openNotification(
  //           "error",
  //           "Error",
  //           "The feedback request failed to send"
  //         );
  //       });
  //   }
  // };

  const showModal = () => {
    setIsModalVisible(true);
    getSoldPackages();
    getPackages();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getUsers = () => {
    const roleMapping: { [key: string]: string } = {
      CALL_TEAM_MANAGER: "CALL_TEAM",
      SUCCESS_TEAM_MANAGER: "SUCCESS_TEAM",
      ACCOUNT_MANAGER_MANAGER: "ACCOUNT_MANAGER",
      SUSPENDED_TEAM_MANAGER: "SUSPENDED_TEAM",
      ADMIN: "ADMIN",
    };

    if (roleName) {
      const teamRole = roleMapping[roleName];
      if (teamRole) {
        UserService.getAll(`?roleName=${teamRole}`)
          .then((response: any) => {
            const newArray = response.map((item: any) => ({
              value: item.id,
              label: `${item.firstName} ${item.lastName}`,
            }));

            setUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [roleName]);

  const handleChangeAssigneUser = (userId: any, row: any) => {
    const payload = {
      callCenterSalesPackageId: row?.callCenterSalesPackage?.id,
      learnerId: row?.learner?.id || null,
      customerId: row?.customer?.id || null,
      prospectCustomerId: row?.prospectCustomer?.id || null,
      userId: userId,
    };

    CallSalesPackageService.updateSalesLog(row.id, payload)
      .then((response: any) => {
        openNotification("success", "Success", "Changed assigned user");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const salesLogColumns = [
    {
      title: "Package Name",
      dataIndex: "callCenterSalesPackage",
      key: "callCenterSalesPackage",
      width: "150px",
      render: (callCenterSalesPackage: any) =>
        callCenterSalesPackage?.name || "-",
    },
    {
      title: "Approval Date",
      dataIndex: "approvalDate",
      key: "approvalDate",
      width: "150px",
      render: (text: any) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    ...(roleName === "CALL_TEAM_MANAGER" ||
    roleName === "SUCCESS_TEAM_MANAGER" ||
    roleName === "SUSPEND_TEAM_MANAGER" ||
    roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "HI5_ACM_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            title: "Team Member",
            dataIndex: "user",
            key: "user",
            width: "150px",
            render: (_: any, row: any) => {
              const userOption = userOptions.find(
                (option: any) => option.value === row?.user?.id
              );
              const displayName = userOption
                ? userOption.label
                : `${row.user.firstName} ${row.user.lastName}`;
              return (
                <span>
                  {userOption ? (
                    <Select
                      showSearch
                      filterOption={(input, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(selectedUser) =>
                        handleChangeAssigneUser(selectedUser, row)
                      }
                      options={userOptions}
                      key={row.id}
                      {...(row?.user?.id && {
                        defaultValue: row.user.id,
                      })}
                      placeholder={"Select"}
                      style={{ width: "180px" }}
                    ></Select>
                  ) : (
                    displayName
                  )}
                </span>
              );
            },
          },
        ]
      : []),
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50px",
      render: (_: any, row: any) => (
        // !row.approvalDate && (
        <Tooltip placement="topLeft" title="Delete">
          <CloseCircleOutlined
            style={{ marginTop: 5, marginLeft: 10, height: 24 }}
            onClick={() => showDeleteSalesLogModal(row.id)}
          >
            Delete
          </CloseCircleOutlined>
        </Tooltip>
      ),
      // ),
    },
  ];

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        let data = response.data;
        const soldPackageIds = salesLog.map(
          (log: any) => log.callCenterSalesPackageId
        );

        let filteredData = data.filter((item: any) => {
          const isSold = soldPackageIds.includes(item.id);
          const hasCommissionRate =
            roleName === "SUCCESS_TEAM_MANAGER" || roleName === "SUCCESS_TEAM"
              ? item.stmCommissionRate !== null
              : roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM"
              ? item.ctmCommissionRate !== null
              : roleName === "SUSPEND_TEAM_MANAGER" ||
                roleName === "SUSPEND_TEAM"
              ? item.suspendCommissionRate !== null
              : roleName === "ACCOUNT_MANAGER_MANAGER" ||
                roleName === "ACCOUNT_MANAGER"
              ? item.acmCommissionRate !== null
              : roleName === "HI5_ACM_MANAGER" || roleName === "HI5_ACM_MEMBER"
              ? item.hi5AcmCommissionRate !== null
              : true;

          return !isSold && hasCommissionRate;
        });

        const newArray = filteredData.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            disabled: !item.isActive,
          };
        });

        const packageNamesMap = response.data.reduce((acc: any, curr: any) => {
          acc[curr.id] = curr.name;
          return acc;
        }, {});

        setPackageNames(packageNamesMap);
        setOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  // const dark = localStorage.getItem('darkMode')

  const getSoldPackages = () => {
    let filter =
      isCustomer === true
        ? `customerId=${customer?.id}`
        : `learnerId=${customer?.id}`;
    CallSalesPackageService.getSalesLog(`?${filter}`)
      .then((response: any) => {
        setSalesLog(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleAddSaleLog = () => {
    const selectedPackageId = form.getFieldValue("selectedPackage");
    const customerOrLearnerId = customer?.id;
    if (!selectedPackageId || !customerOrLearnerId) {
      console.error("Selected package or customer ID is missing!");
      return;
    }
    const packageData = {
      callCenterSalesPackageId: selectedPackageId,
      customerId: isCustomer ? customerOrLearnerId : undefined,
      learnerId: !isCustomer ? customerOrLearnerId : undefined,
    };

    Object.keys(packageData).forEach(
      (key) =>
        packageData[key as keyof typeof packageData] === undefined &&
        delete packageData[key as keyof typeof packageData]
    );
    setLoading(true);
    CallSalesPackageService.createSalesLog(packageData)
      .then((response) => {
        if (response.status === 200) {
          openNotification(
            "success",
            "Success",
            "Sales log created successfully"
          );
          getSoldPackages();
          // setIsModalVisible(false);
        } else {
          openNotification("error", "Error", "Failed to create sales log");
        }
      })
      .catch((error) => {
        console.error("Error creating sales log:", error);
        openNotification("error", "Error", "Failed to create sales log");
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteSaleLog = (salesLogId: number) => {
    setLoading(true);
    CallSalesPackageService.removeSalesLog(salesLogId)
      .then((response) => {
        openNotification(
          "success",
          "Success",
          "Sales log deleted successfully"
        );
        getSoldPackages();
      })
      .catch((error) => {
        console.error("Error deleting sales log:", error);
        openNotification("error", "Error", "Failed to delete sales log");
      })
      .finally(() => setLoading(false));
  };

  const showDeleteSalesLogModal = (salesLogId: any) => {
    Modal.confirm({
      title: "Delete Sale Log",
      content: "Are you sure you want to delete this sale log?",
      onOk: () => handleDeleteSaleLog(salesLogId),
      onCancel: () => {},
    });
  };

  const showAddPackageModal = () => {
    Modal.confirm({
      title: "Package Selection",
      content: (
        <Select
          style={{ width: "100%" }}
          placeholder="Package select"
          onChange={(value) => form.setFieldsValue({ selectedPackage: value })}
        >
          <Select.Option key={0} value={null}>
            Select
          </Select.Option>
          {options.map(
            (option: any) =>
              !option.disabled && (
                <Select.Option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </Select.Option>
              )
          )}
        </Select>
      ),
      okText: "Add Sale Log",
      onOk: () => handleAddSaleLog(), // Burada direkt olarak fonksiyonu çağırıyoruz
      onCancel: () => {},
    });
  };

  useEffect(() => {
    if (customer) {
      getPackages();
    }
    // eslint-disable-next-line
  }, [customer]);

  // const handleBackClick = () => {
  //   if (
  //     currentFilterText == "" ||
  //     currentFilterText ==
  //       `?page=${currentPage}&size=${currentPageSize}&state=Active`
  //   ) {
  //     navigate(-1);
  //   } else {
  //     navigate("/", { state: location.state });
  //   }
  // };

  return (
    <>
      <Row justify={"start"} align={"middle"}>
        <Button
          type="default"
          onClick={() => navigate(-1)}
          style={{ marginRight: 16 }}
        >
          <ArrowLeftOutlined />
        </Button>
        <p className="page-title">User Detail</p>
      </Row>
      <Row justify={"space-between"} align={"middle"}>
        <Row style={{ flexDirection: "column", alignItems: "start" }}>
          <p
            style={{
              fontSize: 24,
              fontWeight: "bold",
              margin: 0,
              marginTop: 10,
            }}
          >
            {customer?.fullName}
          </p>
          <Row align={"middle"}>
            <p
              style={{
                marginRight: 20,
                color: "#292D32",
                fontSize: 12,
                display: "flex",
                alignItems: "end",
              }}
            >
              {" "}
              <img
                src={phoneIcon}
                alt="phone-icon"
                style={{ marginRight: 2 }}
              />
              <CopyClipboard
                text={customer?.phone ?? customer?.phoneNumber}
              ></CopyClipboard>{" "}
            </p>
            <p
              style={{
                marginRight: 10,
                color: "#292D32",
                fontSize: 12,
                display: "flex",
                alignItems: "end",
              }}
            >
              {" "}
              <img
                src={smsIcon}
                alt="sms-icon"
                style={{ marginRight: 2 }}
              />{" "}
              <CopyClipboard text={customer?.email}></CopyClipboard>{" "}
            </p>
          </Row>
        </Row>
        {roleName !== "SUCCESS_TEAM_REPORTER" ? (
          <Row justify={"center"} align={"middle"} style={{ marginBottom: 10 }}>
            <Select
              onChange={(selectedStatus) =>
                handleChangeStatus(selectedStatus, customer?.id)
              }
              options={customerStatusOptions}
              key={customer?.id}
              {...(customer?.callCenterStatus1?.id && {
                defaultValue: customer.callCenterStatus1.id,
              })}
              placeholder={"Select Status"}
              style={{ width: "180px", marginRight: 16 }}
            ></Select>
            {/* <Popconfirm
                        title="Are you sure to send this feedback request?"
                        onConfirm={handleFeedback}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button style={{ fontSize: 10, fontWeight: 'bold' }}>
                            Feedback Request
                        </Button>
                    </Popconfirm> */}

            <Row justify={"end"}>
              <Button type="primary" onClick={showModal}>
                <ShoppingCartOutlined /> Sales
              </Button>
              <Modal
                title="Sales"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Row justify={"end"}>
                  <Button
                    type="primary"
                    style={{ marginBottom: 16 }}
                    onClick={showAddPackageModal}
                  >
                    Add Sale Log
                  </Button>
                </Row>
                <Table
                  dataSource={salesLog}
                  columns={salesLogColumns}
                  rowKey="id"
                />
              </Modal>
            </Row>
            {callForm()}
          </Row>
        ) : null}
      </Row>

      <Row gutter={[16, 16]}>
        <Col sm={24} lg={8}>
          {customer?.learner === null ? (
            <Row>
              <Col span={24}>
                <SalesCard
                  customer={customer}
                  title="Total Calls"
                  description={`${callInfo?.totalCalls} Calls`}
                  ratio={callInfo?.percentageWithDuration}
                  orderCount={18}
                  id={customer?.id}
                />
              </Col>
              <Col span={24} style={{ marginTop: "16px" }}>
                <AdditionalPackageHistoryCard
                  id={customer?.id}
                  autoGoldSubscriptionRenewalEnabled={
                    customer?.autoGoldSubscriptionRenewalEnabled
                  }
                  autoSlpSubscriptionRenewalEnabled={
                    customer?.autoSlpSubscriptionRenewalEnabled
                  }
                />
              </Col>
            </Row>
          ) : (
            // <Row>
            //   <Col span={24}>
            <SalesCard
              customer={customer}
              title="Total Calls"
              description={`${callInfo?.totalCalls} Calls`}
              ratio={callInfo?.percentageWithDuration}
              orderCount={18}
              id={customer?.id}
            />
            //   </Col>
            // </Row>
          )}
        </Col>
        <Col sm={24} lg={11}>
          {/* <SalesHistory /> */}
          <CallHistory
            customer={customer}
            isCustomer={isCustomer}
            setTriggerUpdate={setTriggerUpdate}
          />
        </Col>
        <Col sm={24} lg={5}>
          <CustomerInfo
            customer={customer}
            isCustomer={isCustomer}
            learner={learnerDetail}
            setTriggerUpdate={setTriggerUpdate}
          />
        </Col>
      </Row>
      {customer?.state === "Suspended" && (
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col sm={24} md={6}>
            <InfoCard
              title="Current Package"
              description={""}
              count={customer?.currentPackage}
            />
          </Col>
          <Col sm={24} md={6}>
            <InfoCard
              title="Product Count"
              description="qty"
              count={customer?.totalProductCount}
            />
          </Col>
          <Col sm={24} md={6}>
            <InfoCard
              title="Order Count"
              description="Product Sales"
              count={customer?.totalOrderCount}
            />
          </Col>
          <Col sm={24} md={6}>
            <InfoCard
              title="Oneamz Order Count"
              description="Product Sales"
              count={customer?.totalOneamzOrderCount}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col sm={24} md={12}>
          <Notes
            notes={customer?.notes}
            isCustomer={isCustomer}
            customerId={customer?.id}
            setTriggerUpdate={setTriggerUpdate}
          />
        </Col>

        <Col sm={24} md={12}>
          <Tasks
            customerName={customer?.fullName}
            tasks={customer?.tasks}
            isCustomer={isCustomer}
            customerId={customer?.id}
            setTriggerUpdate={setTriggerUpdate}
            userOptions={userOptions}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserDetail;
