import { filter } from "lodash";
import http from "../http-common";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ICustomerRequestData } from "../types/Customer";

const customerSummary = (filteredDate: string = '') => {
  return http.get<any>(`/customer-summary${filteredDate}`);
};

const customerSummaryMe = (filteredDate: string = '') => {
  return http.get<any>(`/users/me/dashboard/customer-summary${filteredDate}`);
};
const customerTotalOrderCount = (filteredDate: string = '') => {
  return http.get<any>(`/customers/sales/total/count${filteredDate}`);
};
const customerTotalOrderCountMe = (filteredDate: string = '') => {
  return http.get<any>(`/users/me/sales/total/count${filteredDate}`);
};
const customerSummaryFeedbackMe = () => {
  return http.get<any>(`/users/me/customer/feedback-avg`);
};
const customerSummaryFeedbackAll = () => {
  return http.get<any>(`/customer-all/feedback-avg`);
};

const salesSummary = (filteredDate: string = '') => {
  return http.get<any>(`/sales-summary${filteredDate}`);
}

const salesSummaryMe = (filteredDate: string = '') => {
  return http.get<any>(`/me/sales-summary${filteredDate}`);
}

const salesSummaryByUser = (userId: number, filteredDate: string = '') => {
  return http.get<any>(`/sales-summary/${userId}${filteredDate}`)
}

const phoneCallSummary = (filteredDate: string = '') => {
  return http.get<any>(`/phone-call-summary${filteredDate}`);
}

const phoneCallSummaryHi5Acm = (filteredDate: string = '') => {
  return http.get<any>(`/dashboard/phone-call-summary${filteredDate}`);
}

const phoneCallSummaryMe = (filteredDate: string = '') => {
  return http.get<any>(`/me/phone-call-summary${filteredDate}`);
}

const phoneCallSummaryByUser = (gsmPortalEmployeeId: number, filteredDate: string = '') => {
  return http.get<any>(`/phone-call-summary/${gsmPortalEmployeeId}${filteredDate}`);
};

const phoneCallSummaryByGsmPortalEmployeeId = (gsmPortalEmployeeIdId: number, filteredData: string = '') => {
  return http.post<any>(`/phone-call-summary/${gsmPortalEmployeeIdId}${filteredData}`);
}

const masSummary = () => {
  return http.get<any>(`/mas-summary`);
}

const getSalesSummaryHi5Acm = (filteredDate: string = '') => {
  return http.get<any>(`/sales-summary/hi5acm${filteredDate}`);
}

const getSalesSummaryMeHi5Acm = (filteredDate: string = '') => {
  return http.get<any>(`/me/sales-summary/hi5acm${filteredDate}`);
}

const getSalesSummaryHi5AcmByUserId = (userId: number, filteredDate: string = '') => {
  return http.get<any>(`/sales-summary/hi5acm/${userId}${filteredDate}`);
}

const getRetentionReportForManager = (filteredDate: string = '') => {
  return http.get<any>(`/manager/retention-reports${filteredDate}`);
}

const getRetentionReportForMember = (filteredDate: string = '') => {
  return http.get<any>(`/member/retention-reports${filteredDate}`);
}


const DashboardService = {
  customerSummary,
  customerSummaryMe,
  customerSummaryFeedbackMe,
  customerSummaryFeedbackAll,
  customerTotalOrderCount,
  customerTotalOrderCountMe,
  salesSummary,
  salesSummaryMe,
  salesSummaryByUser,
  phoneCallSummary,
  phoneCallSummaryHi5Acm,
  phoneCallSummaryMe,
  phoneCallSummaryByUser,
  phoneCallSummaryByGsmPortalEmployeeId,
  masSummary,
  getSalesSummaryHi5Acm,
  getSalesSummaryMeHi5Acm,
  getSalesSummaryHi5AcmByUserId,
  getRetentionReportForManager,
  getRetentionReportForMember
};

export default DashboardService;