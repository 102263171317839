import React, { ReactNode, useEffect, useState } from "react";
import { Avatar, Button, Col, Layout, Row, Tooltip } from "antd";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { refrestToken } from "../utils";
import logo from "../assets/logo.svg";
import dashboardIcon from "../assets/dashboard.svg";
import taskIcon from "../assets/task.svg";
import userIcon from "../assets/users.svg";
import learnerIcon from "../assets/learners.svg";
import userPlusIcon from "../assets/users-add.svg";
import userSuspendedIcon from "../assets/users-suspended.svg";
import userAmazonSuspendedIcon from "../assets/users-amazon-suspended.svg";
import userDropoutIcon from "../assets/users-dropout.svg";
import employeeCardIcon from "../assets/employee-card.svg";
import timeOffScheduleIcon from "../assets/time-off-schedule.svg";
import logoutIcon from "../assets/logout.svg";
import SettingIcon from "../assets/setting.svg";
import ReportIcon from "../assets/calendar.svg";
import CallCenterTeamIcon from "../assets/callCenterTeam.svg";
import SuccessTeamIcon from "../assets/successTeam.svg";
import AccountManagerIcon from "../assets/accountManager.svg";
import Hi5ACMIcon from "../assets/hi5ACMTeam.svg";
import SuspendTeamIcon from "../assets/suspendTeam.svg";
import MasTeamIcon from "../assets/MasTeam.svg";
import AdminIcon from "../assets/admin.svg";
import { Notification } from "../components/Dashboard/Notification";

const { Sider, Content } = Layout;

let avatarSrc = require(`../assets/animojis/Number=1.png`);

interface CustomLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<CustomLayoutProps> = ({ children }) => {
  const [profilePhoto, setProfilePhoto] = useState<any>(avatarSrc);
  const roleName = localStorage.getItem("roleName");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    refrestToken();
  }, [location]);

  useEffect(() => {
  try {
    const profilePhoto = localStorage.getItem("profilePhoto");
    if (profilePhoto !== null) {
      avatarSrc = require(`../assets/animojis/Number=${profilePhoto}.png`);
      setProfilePhoto(avatarSrc);
    }
  } catch (error) {
    console.error("Avatar yüklenirken bir hata oluştu:", error);
  }
}, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null) {
      navigate("/login");
    }
  }, [navigate]);


  const logout = () => {
    [
      "externalAccessToken",
      "externalRefreshToken",
      "accessToken",
      "refreshToken",
      "accessTokenExpiredAt",
      "refreshTokenExpiredAt",
      "customerFilterText",
      "retentionFilterText",
      "hi5CustomerFilterText",
      "learnerFilterText",
      "prospectFilterText",
      "softwareSuspendedFilterText",
      "amazonSuspendedFilterText",
      "masCustomerFilterText",
    ].forEach((item) => localStorage.removeItem(item));
    navigate("/login");
  };

  const clearFilters = (path: string) => {
  // Silinmeyecek filtreleri tanımlayın
  const filterExclusions: Record<string, string[]> = {
    "/learners": ["learnerFilterText"],
    "/customers": ["customerFilterText"],
    "/retention-customers": ["retentionFilterText"],
    "/hi5-customers": ["hi5CustomerFilterText"],
    "/prospect-customers": ["prospectFilterText"],
    "/stm-suspended-customers": ["softwareSuspendedFilterText"],
    "/amazon-suspended-applicants": ["amazonSuspendedFilterText"],
    "/mas-customers": ["masCustomerFilterText"],
  };

  // Tüm filtreleri tanımlayın
  const allFilters = [
    "learnerFilterText",
    "customerFilterText",
    "retentionFilterText",
    "hi5CustomerFilterText",
    "prospectFilterText",
    "softwareSuspendedFilterText",
    "amazonSuspendedFilterText",
    "masCustomerFilterText",
  ];

  // Mevcut path'e göre silinmeyecek filtreleri belirleyin
  const exclusions = filterExclusions[path] || [];

  // Silinecek filtreleri belirleyin
  const filtersToRemove = allFilters.filter(
    (filter) => !exclusions.includes(filter)
  );

  // Belirlenen filtreleri temizleyin
  filtersToRemove.forEach((filter) => localStorage.removeItem(filter));
};

  const roleItems = [
    { role: ["CALL_TEAM_MANAGER", "CALL_TEAM"], icon: CallCenterTeamIcon, title: "Call Center", path: "/" },
    { role: ["ACCOUNT_MANAGER_MANAGER", "ACCOUNT_MANAGER"], icon: AccountManagerIcon, title: "Account Manager", path: "/" },
    { role: ["HI5_ACM_MANAGER", "HI5_ACM_MEMBER"], icon: Hi5ACMIcon, title: "Hi5 Account Manager", path: "/" },
    { role: ["SUCCESS_TEAM_MANAGER", "SUCCESS_TEAM"], icon: SuccessTeamIcon, title: "Success Team", path: "/" },
    { role: ["SUSPEND_TEAM_MANAGER", "SUSPEND_TEAM"], icon: SuspendTeamIcon, title: "Suspend Team", path: "/" },
    { role: ["MAS_TEAM_MANAGER", "MAS_TEAM_MEMBER"], icon: MasTeamIcon, title: "Mas Team", path: "/" },
    { role: ["ADMIN"], icon: AdminIcon, title: "Admin", path: "/" }
  ] 

  const menuItems = [
    { role: ["ADMIN", "CALL_TEAM_MANAGER", "CALL_TEAM", "ACCOUNT_MANAGER_MANAGER", "ACCOUNT_MANAGER", "HI5_ACM_MANAGER", "HI5_ACM_MEMBER", "SUCCESS_TEAM_MANAGER", "SUCCESS_TEAM", "SUSPEND_TEAM_MANAGER", "SUSPEND_TEAM", "MAS_TEAM_MANAGER", "MAS_TEAM_MEMBER"], icon: dashboardIcon, title: "Home", path: "/" },
    { role: ["ADMIN", "CALL_TEAM_MANAGER", "CALL_TEAM", "ACCOUNT_MANAGER_MANAGER", "ACCOUNT_MANAGER", "HI5_ACM_MANAGER", "HI5_ACM_MEMBER", "SUCCESS_TEAM_MANAGER", "SUCCESS_TEAM", "SUSPEND_TEAM_MANAGER", "SUSPEND_TEAM"], icon: taskIcon, title: "Task", path: "/task" },
    { role: ["SUCCESS_TEAM_MANAGER", "SUCCESS_TEAM", "SUCCESS_TEAM_REPORTER", "ADMIN"], icon: learnerIcon, title: "Learners", path: "/learners" },
    { role: ["ACCOUNT_MANAGER", "ACCOUNT_MANAGER_MANAGER", "ADMIN", "CALL_TEAM", "CALL_TEAM_MANAGER"], icon: userPlusIcon, title: "Customers", path: "/customers" },
    { role: ["SUCCESS_TEAM", "SUCCESS_TEAM_MANAGER", "SUCCESS_TEAM_REPORTER", "ADMIN"], icon: userPlusIcon, title: "Retention Customer List", path: "/retention-customers" },
    { role: ["HI5_ACM_MANAGER", "HI5_ACM_MEMBER", "ADMIN"], icon: userPlusIcon, title: "Hi5 Customers", path: "/hi5-customers" },
    { role: ["CALL_TEAM", "CALL_TEAM_MANAGER", "ADMIN"], icon: userIcon, title: "Call Center Customers", path: "/prospect-customers" },
    { role: ["SUCCESS_TEAM", "SUCCESS_TEAM_MANAGER", "SUCCESS_TEAM_REPORTER", "ADMIN"], icon: userSuspendedIcon, title: "Software Suspended Customer List", path: "/stm-suspended-customers" },
    { role: ["SUSPEND_TEAM", "SUSPEND_TEAM_MANAGER", "ADMIN"], icon: userAmazonSuspendedIcon, title: "Amazon Suspended Applicants", path: "/amazon-suspended-applicants" },
    { role: ["MAS_TEAM_MANAGER", "MAS_TEAM_MEMBER", "ADMIN"], icon: userIcon, title: "MAS Customers", path: "/mas-customers" },
    { role: ["ACCOUNT_MANAGER_MANAGER", "ACCOUNT_MANAGER", "HI5_ACM_MANAGER", "HI5_ACM_MEMBER", "SUCCESS_TEAM_MANAGER", "SUCCESS_TEAM", "ADMIN"], icon: userDropoutIcon, title: "Dropout Feedback List", path: "/dropout-feedback" },
    { role: [], icon: employeeCardIcon, title: "Employees", path: "/employees" },
    { role: [], icon: timeOffScheduleIcon, title: "Time Off Schedule", path: "/time-off-schedule" },
    { role: ["ACCOUNT_MANAGER_MANAGER", "ADMIN"], icon: ReportIcon, title: "Report", path: "reports/acm-reports/call-history" },
    { role: ["SUCCESS_TEAM_MANAGER"], icon: ReportIcon, title: "Report", path: "reports/stm-reports/learner-status" },
    { role: ["HI5_ACM_MANAGER"], icon: ReportIcon, title: "Report", path: "/reports/hi5-acm-reports/call-history" },
    { role: ["CALL_TEAM_MANAGER"], icon: ReportIcon, title: "Report", path: "/reports/ctm-reports/call-history" },
    { role: ["SUSPEND_TEAM_MANAGER"], icon: ReportIcon, title: "Report", path: "/reports/common-reports/feedback-report" },
    { role: ["SUCCESS_TEAM_MANAGER", "ADMIN"], icon: SettingIcon, title: "Settings", path: "/settings/learner-status" },
    { role: ["CALL_TEAM_MANAGER"], icon: SettingIcon, title: "Settings", path: "/settings/call-status" },
    { role: ["ACCOUNT_MANAGER_MANAGER"], icon: SettingIcon, title: "Settings", path: "/settings/acm-status" },
    { role: ["HI5_ACM_MANAGER"], icon: SettingIcon, title: "Settings", path: "/settings/hi5-acm-status" },
    { role: ["SUSPEND_TEAM_MANAGER"], icon: SettingIcon, title: "Settings", path: "/settings/amazon-suspended-applicant-status" },
    { role: ["MAS_TEAM_MANAGER"], icon: SettingIcon, title: "Settings", path: "/settings/users" },
  ];

  return (
    <Layout className="oneamz-layout">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => console.log(broken)}
        onCollapse={(collapsed, type) => console.log(collapsed, type)}
      >
        <Row style={{ height: "100vh" }} justify={"center"}>
          <Col>
            <Row justify={"center"} style={{ margin: "30px 0px 15px" }}>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </Row>
          </Col>
          <Col>
            <>
            {roleItems
              .filter(item => item.role.length === 0 || item.role.includes(roleName || ""))
              .map((item, index) => (
               <Tooltip placement="right" title={item.title} key={index}>   
                <Row justify={"center"} style={{ marginBottom: "25px" }}>
                  <img
                    src={item.icon}
                    alt={item.title}
                    style={{ marginTop: "5px" }}
                  />
                </Row>
              </Tooltip>
            ))}
            {menuItems
              .filter(item => item.role.length === 0 || item.role.includes(roleName || ""))
              .map((item, index) => (
               <Tooltip placement="right" title={item.title} key={index}>   
                <Row justify={"center"} style={{ marginBottom: "25px" }}>
                  <NavLink
                    to={item.path}
                    className="nav-link"
                    onClick={() => clearFilters(item.path)}
                  >
                    <img
                      src={item.icon}
                      alt={item.title}
                      style={{ marginTop: "5px" }}
                    />
                  </NavLink>
                </Row>
              </Tooltip>
            ))}
            </>
          </Col>
          <Col>
          <Tooltip placement="right" title={"Profile"}>
            <Row
              justify={"center"}
              style={{ marginBottom: "25px", width: "70px" }}
            >
              <Link
                to="/profile"
                onClick={() => clearFilters("/profile")}
              >
                <Avatar size={50} src={profilePhoto} />
              </Link>
            </Row>
            </Tooltip>
            <Row
              justify={"center"}
              style={{ marginBottom: "25px", width: "70px" }}
            >
              <Button type="link" onClick={logout}>
                <img src={logoutIcon} alt="logoutIcon" />
              </Button>
            </Row>
          </Col>
        </Row>
      </Sider>
      <Layout>
        <Content
          style={{
            margin: "16px 16px 16px 8px",
            padding: 24,
            minHeight: "calc(100vh - 32px)",
            background: "#F0F0F0",
            position: "relative",
          }}
        >
          <Row style={{ position: "absolute", right: "15px", top: "15px" }}>
             <Notification />
          </Row>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
