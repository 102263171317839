import { Col, Row, Table } from "antd";
import CallTeamReportService from "../../../../services/CallTeamReportService";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";

const CtmMemberBasedTotalCustomerReport = ({
  dateRange,
}: {
  dateRange: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = new Date(dateRange[0]).toISOString();
      const formattedEndDate = new Date(dateRange[1]).toISOString();
      getMemberBasedTotalCustomerReport(formattedStartDate, formattedEndDate);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getMemberBasedTotalCustomerReport = (
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    CallTeamReportService.getMemberBasedTotalCustomerReport(startDate, endDate)
      .then((response) => {
        setData(response.data.reportList);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Call Team Member",
      dataIndex: "member",
      key: "member",
      width: 300,
      align: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) =>
        a?.member?.firstName.localeCompare(b?.member?.firstName),
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.member?.firstName} ${record?.member?.lastName}`}
          >
            <b>
              {record?.member?.firstName} {record?.member?.lastName}
            </b>
          </div>
        );
      },
    },
    {
      title: "Total Customer",
      dataIndex: "customerCount",
      key: "customerCount",
      width: 150,
      align: "center",
      sorter: (a: any, b: any) => a?.customerCount - b?.customerCount,
      render: (text: any) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Member Based Total Customer Report</h3>
        <Table
          rowKey={(record) => record?.member?.id}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{ x: "100%" }}
          sticky
          pagination={false}
          summary={(pageData) => {
            const totalCustomerCount = pageData?.reduce(
              (total: number, record: any) => total + record?.customerCount,
              0
            );

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={1} align="left">
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <b>{totalCustomerCount}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default CtmMemberBasedTotalCustomerReport;
