import { Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CallCenterSalesLogService from "../../../../services/CallCenterSalesLogService";

const CtmSalesLogReport = ({ dateRange }: { dateRange: any }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = new Date(dateRange[0]).toISOString();
      const formattedEndDate = new Date(dateRange[1]).toISOString();
      getSalesLogReport(formattedStartDate, formattedEndDate);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getSalesLogReport = (startDate: string, endDate: string) => {
    setLoading(true);
    CallCenterSalesLogService.getSalesLogReport(startDate, endDate, "CALL_TEAM")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUniqueSalesDataKeys = (data: any[]) => {
    const allKeys = data?.reduce((keys, item) => {
      return keys.concat(Object.keys(item?.salesDataMap));
    }, []);
    return Array.from(new Set(allKeys));
  };

  const salesDataKeys = getUniqueSalesDataKeys(data);

  const extractNumericValue = (value: string): number => {
    const match = value.match(/^(\d+)/);
    return match ? parseFloat(match[1]) : 0;
  };

  const capitalize = (text: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Call Team Member",
      dataIndex: "user",
      key: "user",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a?.user?.firstName.localeCompare(b?.user?.firstName),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.user?.firstName} ${record?.user?.lastName}`}
          >
            <b>
              {record?.user?.firstName} {record?.user?.lastName}
            </b>
          </div>
        );
      },
    },
    ...salesDataKeys
      .filter((key: any) => {
        return data.some((item: any) => {
          const value = item.salesDataMap[key] || "0 (0)";
          const match = value.match(/^(\d+)\s\((\d+)\)$/);
          const currentValue = match ? parseFloat(match[1]) : 0;
          const previousValue = match ? parseFloat(match[2]) : 0;
          return currentValue !== 0 || previousValue !== 0;
        });
      })
      .map((key: any) => ({
        title: `${capitalize(key)}*`,
        dataIndex: "salesDataMap",
        key,
        width: 150,
        align: "center" as const,
        sorter: (a: any, b: any) => {
          const aValue = extractNumericValue(a?.salesDataMap[key] || "0");
          const bValue = extractNumericValue(b?.salesDataMap[key] || "0");
          return aValue - bValue;
        },
        render: (salesDataMap: any) => {
          return `${salesDataMap[key] || "0 (0)"} qty`;
        },
      })),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Sales Log Report</h3>
        <Table
          rowKey={(record) => record?.user?.id}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{ x: "100%" }}
          sticky
          pagination={false}
          summary={(pageData) => {
            const salesDataTotals = salesDataKeys.map((key: any) => {
              const totals = pageData.reduce(
                (total, record) => {
                  const value = record.salesDataMap[key];
                  const match = value?.match(/^(\d+)\s\((\d+)\)$/);
                  if (match) {
                    const currentValue = parseFloat(match[1]);
                    const previousValue = parseFloat(match[2]);
                    if (currentValue !== 0 || previousValue !== 0) {
                      total.current += currentValue;
                      total.previous += previousValue;
                    }
                  }
                  return total;
                },
                { current: 0, previous: 0 }
              );
              return totals.current !== 0 || totals.previous !== 0
                ? `${totals.current} (${totals.previous}) qty`
                : null;
            });
        
            return (
              <Table.Summary.Row className="summary-cell">
                <Table.Summary.Cell
                  index={0}
                  colSpan={1}
                  className="summary-cell"
                >
                  <b>Total</b>
                </Table.Summary.Cell>
                {salesDataKeys.map((key: any, index: any) => {
                  const total = salesDataTotals[index];
                  if (total) {
                    return (
                      <Table.Summary.Cell
                        index={index + 1}
                        key={index}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{total}</b>
                      </Table.Summary.Cell>
                    );
                  } else {
                    return null;
                  }
                })}
              </Table.Summary.Row>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default CtmSalesLogReport;
