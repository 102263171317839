import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Col, Row, Space, Table } from "antd";
import "./acm-dashboard.css";
import DashboardService from "../../../services/DashboardService";
import ReactApexChart from "react-apexcharts";
import { getProfilePhotoPath } from "../../../utils";
import goldIcon from "../../../assets/gold.svg";
import silverIcon from "../../../assets/silver.svg";
import bronzIcon from "../../../assets/bronz.svg";
import UserDetailsModal from "./UserDetailsModal";

interface TotalSalesCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const TotalSalesCard: React.FC<TotalSalesCardProps> = ({
  title,
  dateRange,
  roleName,
}) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [salesSummary, setSalesSummary] = useState<any>([]);
  // eslint-disable-next-line
  const [salesSummaryMe, setSalesSummaryMe] = useState<any>([]);
  const [allTeamUsers, setAllTeamUsers] = useState<any>([]);
  const [userDetailsVisible, setUserDetailsVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [total, setTotal] = useState<number>(0);
  const [totalCommission, setTotalCommission] = useState<number>(0);
  const [totalTurnover, setTotalTurnover] = useState<number>(0);
  const [salesRatio, setSalesRatio] = useState<number>(0);
  const [totalMe, setTotalMe] = useState<number>(0);
  const [totalCommissionMe, setTotalCommissionMe] = useState<number>(0);
  const [totalTurnoverMe, setTotalTurnoverMe] = useState<number>(0);
  // eslint-disable-next-line
  const [salesRatioMe, setSalesRatioMe] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  // eslint-disable-next-line
  const [options, setOptions] = useState<any>({
    chart: {
      type: "donut",
    },
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        track: {
          background: "#f4f4f4",
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "15px",
          },
          value: {
            fontWeight: 600,
          },
        },
      },
    },
    labels: ["Commission"],
    colors: ["#EEC73B"],
    tooltip: {
      enabled: true,
      theme: "dark",
      y: {
        formatter: function (val: number) {
          return val.toFixed(2) + "%";
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "15px",
                },
                value: {
                  fontSize: "15px",
                },
              },
            },
          },
        },
      },
      // {
      //   breakpoint: 576,
      //   options: {
      //     plotOptions: {
      //       radialBar: {
      //         dataLabels: {
      //           name: {
      //             fontSize: "12px",
      //           },
      //           value: {
      //             fontSize: "12px",
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleUserClick = (user: any) => {
    setSelectedUser(user);
    setUserDetailsVisible(true);
  };

  const fetchSalesSummary = async (formattedDateRange: string) => {
    try {
      if (roleName === "CALL_TEAM_MANAGER") {
        const summaryResponse = await DashboardService.salesSummary(
          `${formattedDateRange}`
        );
        const {
          salesPackageCount,
          totalCommissionAmount,
          totalTurnoverAmount,
        } = summaryResponse.data;
        setSalesSummary(summaryResponse.data);
        const UserData = summaryResponse.data.userList.sort(
          (a: any, b: any) => b.salesPackageCount - a.salesPackageCount
        );
        setAllTeamUsers(UserData);
        setTotal(salesPackageCount || 0);
        setTotalCommission(totalCommissionAmount || 0.0);
        setTotalTurnover(totalTurnoverAmount || 0.0);
        const ratio = (
          (totalCommissionAmount / totalTurnoverAmount) *
          100
        ).toFixed(2);
        setSalesRatio(isNaN(parseFloat(ratio)) ? 0 : parseFloat(ratio));
      } else {
        const summaryMeResponse = await DashboardService.salesSummaryMe(
          `${formattedDateRange}`
        );
        const {
          salesPackageCount,
          totalCommissionAmount,
          totalTurnoverAmount,
        } = summaryMeResponse.data;
        setSalesSummaryMe(summaryMeResponse.data);
        setTotalMe(salesPackageCount || 0);
        setTotalCommissionMe(totalCommissionAmount || 0.0);
        setTotalTurnoverMe(totalTurnoverAmount || 0.0);
        const ratio = (
          (totalCommissionAmount / totalTurnoverAmount) *
          100
        ).toFixed(2);
        setSalesRatio(isNaN(parseFloat(ratio)) ? 0 : parseFloat(ratio));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      const [startDate, endDate] = dateRange;
      if (startDate && endDate) {
        const formattedDateRange = `?startDate=${startDate}&endDate=${endDate}`;
        setLoading(true);
        fetchSalesSummary(formattedDateRange);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, roleName]);

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Call Team Member
        </p>
      ),
      dataIndex: "firstName",
      key: "firstName",
      ellipsis: true,
      width: 200,
      render: (text: string, record: any) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(record?.user?.profilePhoto || 1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.user?.firstName} {record?.user?.lastName}
          </b>
        </Row>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
            textAlign: "right",
          }}
        >
          Total Sales
        </p>
      ),
      dataIndex: "salesPackageCount",
      key: "salesPackageCount",
      width: 100,
      align: "right" as const,
      render: (text: string, record: any, index: number) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {index < 3 && (
            <img
              src={
                index === 0
                  ? goldIcon
                  : index === 1
                  ? silverIcon
                  : index === 2
                  ? bronzIcon
                  : ""
              }
              alt="icon"
              style={{ marginRight: 16 }}
            />
          )}
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              textAlign: "right",
              fontWeight: "bold",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text}
          </p>
        </div>
      ),
    },
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      {roleName === "CALL_TEAM_MANAGER" && (
        <p className="description" style={{ marginBottom: "-10px" }}>
          Total Sales: {total}
        </p>
      )}
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={13} md={13}>
          <ReactApexChart
            options={options}
            series={
              roleName === "CALL_TEAM_MANAGER" ? [salesRatio] : [salesRatioMe]
            }
            type="radialBar"
            height={200}
          />
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Row
            align="middle"
            justify="center"
            style={{
              margin:
                windowWidth < 480
                  ? "-30px 0px 10px 0px"
                  : windowWidth < 576
                  ? "-20px 0px 10px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Space direction="vertical" size={0}>
              <Row gutter={[3, 3]}>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "left" : "left",
                  }}
                >
                  <Badge
                    color="#f4f4f4"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Total:{" "}
                        <b>
                          {roleName === "CALL_TEAM_MANAGER" ? total : totalMe}
                        </b>{" "}
                        sales
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "right" : "left",
                  }}
                >
                  <Badge
                    color="#EEC73B"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Commission:{" "}
                        <b>
                          $
                          {roleName === "CALL_TEAM_MANAGER"
                            ? totalCommission
                            : totalCommissionMe}
                        </b>
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "center" : "left",
                  }}
                >
                  <Badge
                    color="#0086FF"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Turnover:{" "}
                        <b>
                          $
                          {roleName === "CALL_TEAM_MANAGER"
                            ? totalTurnover
                            : totalTurnoverMe}
                        </b>
                      </span>
                    }
                  />
                </Col>
              </Row>
            </Space>
          </Row>
        </Col>
      </Row>
      {roleName === "CALL_TEAM_MANAGER" && (
        <>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(record) => record?.id}
                columns={columns}
                dataSource={allTeamUsers}
                pagination={false}
                scroll={{ x: "100%", y: 185 }}
                size="small"
                className="dashboard-table"
                onRow={(record) => {
                  return {
                    onClick: () => handleUserClick(record),
                    style: { cursor: "pointer" },
                  };
                }}
              />
            </Col>
          </Row>
          <UserDetailsModal
            visible={userDetailsVisible}
            onClose={() => setUserDetailsVisible(false)}
            userDetails={selectedUser}
          />
        </>
      )}
    </Card>
  );
};

export default TotalSalesCard;
