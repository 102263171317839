import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Tooltip,
} from "antd";
import "./task-detail.css";
import { useNavigate, useParams } from "react-router-dom";
import callIcon from "../assets/call.svg";
import phoneIcon from "../assets/phone.svg";
import smsIcon from "../assets/sms.svg";
import { generatePassword, openNotification } from "../utils";
import { CopyClipboard } from "../components/General/CopyClipboard";
import { PhoneOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import AmazonSuspendedApplicantService from "../services/AmazonSuspendedApplicantService";
import CallHistory from "../components/AmazonSuspendedApplicantDetail/CallHistory";
import CustomerInfo from "../components/AmazonSuspendedApplicantDetail/CustomerInfo";
import Notes from "../components/AmazonSuspendedApplicantDetail/Notes";
import Tasks from "../components/AmazonSuspendedApplicantDetail/Tasks/Tasks";
import LearnerService from "../services/LearnerService";
import UserService from "../services/UserService";
import { IAmazonSuspendedApplicantSuspendTypesResponseData } from "../types/AmazonSuspendedApplicantSuspendTypes";
import { IAmazonSuspendedApplicantStatusResponseData } from "../types/AmazonSuspendedApplicantStatus";
import Attachments from "../components/AmazonSuspendedApplicantDetail/Attachments";
import AdditionalPackageHistoryCard from "../components/AmazonSuspendedApplicantDetail/AdditionalPackageHistoryCard";
import { FiPhone } from "react-icons/fi";
import StatusService from "../services/StatusService";
import { IAmazonSuspendedApplicantServiceTypesResponseData } from "../types/AmazonSuspendedApplicantServiceTypes";

const { Item } = Form;

const AmazonSuspendedApplicantDetail = () => {
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [customer, setCustomer] = useState<any>(null);
  const [form] = Form.useForm();
  const [gsmPortalEmployeeId, setGsmPortalEmployeeId] = useState("");
  const [customerStatusOptions, setCustomerStatusOptions] = useState<
    IAmazonSuspendedApplicantStatusResponseData[]
  >([]);
  const [customerSuspendTypeOptions, setCustomerSuspendTypeOptions] = useState<
    IAmazonSuspendedApplicantSuspendTypesResponseData[]
  >([]);
  const [customerSuspendServiceTypeOptions, setCustomerSuspendServiceTypeOptions] = useState<
  IAmazonSuspendedApplicantServiceTypesResponseData[]
>([]);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(true);
  const roleName = localStorage.getItem("roleName");
  const navigate = useNavigate();

  const getUsers = () => {
    if (roleName === "SUSPEND_TEAM_MANAGER") {
      UserService.getAll("?roleName=SUSPEND_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else if (roleName === "ADMIN") {
      UserService.getAll("?roleName=ADMIN")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (roleName !== "SUSPEND_TEAM") {
      getUsers();
    }
    // eslint-disable-next-line
  }, [roleName]);

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line
  }, [id, triggerUpdate]);

  const getCustomerStatus = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_STATUS",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerSuspendType = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_SUSPEND_TYPE",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerSuspendTypeOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerServiceType = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_SERVICE_TYPE",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerSuspendServiceTypeOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let netGsmId = localStorage.getItem("gsmPortalEmployeeId");
    if (netGsmId !== null && netGsmId !== "") {
      setGsmPortalEmployeeId(netGsmId);
    }
    getCustomerStatus();
    getCustomerSuspendType();
    getCustomerServiceType();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (customer?.amazonSuspendedApplicantStatus) {
      const selectedStatus = customerStatusOptions.find(
        (option: any) =>
          option.value === customer.amazonSuspendedApplicantStatus.id
      );
      const isResolved =
        selectedStatus?.label === "Çözüldü" ||
        selectedStatus?.label === "ÇÖZÜLDÜ";
      setAreButtonsDisabled(!isResolved);
    }
  }, [customer, customerStatusOptions]);

  const getCustomer = () => {
    AmazonSuspendedApplicantService.get(id)
      .then((response: any) => {
        setCustomer(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  form.setFieldsValue({
    phoneNumber: customer?.phone,
  });

  const handleStartCall = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        openNotification("success", "Calling", values.phoneNumber);
        LearnerService.learnerCall(gsmPortalEmployeeId, values.phoneNumber)
          .then((response: any) => {
            if (response.data?.status === "Success") {
              openNotification(
                "success",
                response.data.message,
                values.phoneNumber,
                5
              );
            } else {
              openNotification(
                "error",
                response.data.message,
                values.phoneNumber,
                5
              );
            }
          })
          .catch((e: Error) => {});
      }
    } catch (error) {
      openNotification(
        "error",
        "Start a call",
        "Phone number must comply with the format"
      );
    }
  };

  const callForm = () => {
    // eslint-disable-next-line
    if (
      gsmPortalEmployeeId !== "" &&
      gsmPortalEmployeeId !== null &&
      !isNaN(Number(gsmPortalEmployeeId)) &&
      gsmPortalEmployeeId !== "null" &&
      gsmPortalEmployeeId
    ) {
      return (
        <Popconfirm
          title="Customer the call"
          description={
            <Form form={form} layout="vertical">
              Are you sure you want to call the customer?
              <Item
                style={{ marginTop: "10px" }}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Phone number must be only digits!",
                  },
                  {
                    len: 11,
                    message: "Phone number must be 11 digits long!",
                  },
                ]}
              >
                <Input />
              </Item>
            </Form>
          }
          okText={
            <>
              <PhoneOutlined /> Call
            </>
          }
          cancelText="No"
          onConfirm={() => handleStartCall()}
        >
          <Button
            type="primary"
            style={{
              marginLeft: 8,
              fontSize: 10,
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
            }}
          >
            <FiPhone style={{ fontSize: "13px", marginRight: "5px" }} />
            Call
          </Button>
        </Popconfirm>
      );
    }

    return (
      <Tooltip
        placement="top"
        title={"Contact your administrator to make a call"}
      >
        <Button
          type="primary"
          style={{
            marginRight: 10,
            fontSize: 10,
            fontWeight: "bold",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={callIcon}
            alt="call-icon"
            style={{ marginRight: 5, marginTop: 0 }}
          />
          Call
        </Button>
      </Tooltip>
    );
  };

  const handleChangeStatus = (statusId: any, id: any) => {
    const selectedStatus = customerStatusOptions.find(
      (option: any) => option.value === statusId
    );
    const isResolved =
      selectedStatus?.label === "Çözüldü" ||
      selectedStatus?.label === "ÇÖZÜLDÜ";
    setAreButtonsDisabled(!isResolved);

    AmazonSuspendedApplicantService.update(id, { statusId })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
        setTriggerUpdate(triggerUpdate + 1);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChangeSuspend = (suspend: any, id: any) => {
    AmazonSuspendedApplicantService.updateSuspend(id, {
      suspendTypeId: suspend,
    })
      .then((response: any) => {
        openNotification("success", "Success", "Changed suspend status");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChangeSuspendServiceType = (serviceType: any, id: any) => {
    AmazonSuspendedApplicantService.updateServiceType(id, {
      serviceTypeId: serviceType,
    })
      .then((response: any) => {
        openNotification("success", "Success", "Changed service type");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const notifyTeam = (teamID: string) => {
    setLoading(true);
    const data = {
      userinfo: customer?.email || customer?.username,
      teamID: teamID,
    };
    AmazonSuspendedApplicantService.notifyDiscord(data)
      .then((response: any) => {
        openNotification(
          "success",
          "Notification sent",
          `Successfully notified ${
            teamID === "1" ? "Support Team" : "Retention Team"
          }`
        );
      })
      .catch((e: Error) => {
        openNotification(
          "error",
          "Notification Error",
          "An unexpected error occurred."
        );
      })
      .finally(() => setLoading(false));
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    navigator.clipboard.writeText(newPassword);
    openNotification("success", "Password Generated", "The password has been generated and copied to clipboard."); 
  };

  return (
    <>
      <Row justify={"start"} align={"middle"}>
        <Button
          type="default"
          onClick={() => navigate(-1)}
          style={{ marginRight: 16 }}
        >
          <ArrowLeftOutlined />
        </Button>
        <p className="page-title">Amazon Suspended Applicant Detail</p>
      </Row>
      <Row justify={"space-between"} align={"middle"}>
        <Row style={{ flexDirection: "column", alignItems: "start" }}>
          <p
            style={{
              fontSize: 24,
              fontWeight: "bold",
              margin: 0,
              marginTop: 10,
            }}
          >
            {customer?.fullName}
          </p>
          <Row align={"middle"}>
            <p
              style={{
                marginRight: 20,
                color: "#292D32",
                fontSize: 12,
                display: "flex",
                alignItems: "end",
              }}
            >
              {" "}
              <img
                src={phoneIcon}
                alt="phone-icon"
                style={{ marginRight: 2 }}
              />
              <CopyClipboard text={customer?.phone}></CopyClipboard>{" "}
            </p>
            <p
              style={{
                marginRight: 10,
                color: "#292D32",
                fontSize: 12,
                display: "flex",
                alignItems: "end",
              }}
            >
              {" "}
              <img
                src={smsIcon}
                alt="sms-icon"
                style={{ marginRight: 2 }}
              />{" "}
              <CopyClipboard text={customer?.email}></CopyClipboard>{" "}
            </p>
          </Row>
        </Row>
        {roleName !== "SUCCESS_TEAM_REPORTER" ? (
          <Row>
            <Row>
              <Button
                type="primary"
                disabled={areButtonsDisabled}
                onClick={() => notifyTeam("1")}
                loading={loading}
                style={{ width: "165px", marginRight: 8 }}
              >
                Notify Support Team
              </Button>
            </Row>
            <Row>
              <Button
                type="primary"
                disabled={areButtonsDisabled}
                onClick={() => notifyTeam("2")}
                loading={loading}
                style={{ width: "165px", marginRight: 8 }}
              >
                Notify Retention Team
              </Button>
            </Row>
            <Row>
              <Button
                type="primary"
                onClick={handleGeneratePassword}
                style={{ width: "165px" }}
              >
                Generate Password
              </Button>
            </Row>
            <Row>{callForm()}</Row>
          </Row>
        ) : null}
      </Row>
      <Row justify={"end"} style={{ marginBottom: 10 }}>
        <Row
          style={{
            flexDirection: "column",
            alignItems: "start",
            marginLeft: 8,
          }}
        >
          <label htmlFor="suspendType" style={{ margin: "4px 0 2px 4px" }}>
            Suspend Type
          </label>
          <Select
            title="Select a Suspend Type"
            id="suspendType"
            onChange={(selectedSuspend) =>
              handleChangeSuspend(selectedSuspend, customer?.id)
            }
            options={customerSuspendTypeOptions}
            key={customer?.id}
            {...(customer?.amazonSuspendedApplicantSuspendType?.id && {
              defaultValue: customer.amazonSuspendedApplicantSuspendType.id,
            })}
            placeholder={"Select Suspend Type"}
            style={{ width: "180px", marginRight: 10 }}
          ></Select>
        </Row>
        <Row
          style={{
            flexDirection: "column",
            alignItems: "start",
            marginLeft: 8,
          }}
        >
          <label htmlFor="serviceType" style={{ margin: "4px 0 2px 4px" }}>
            Service Type
          </label>
          <Select
            title="Select a Service Type"
            id="serviceType"
            onChange={(selectedServiceType) =>
              handleChangeSuspendServiceType(selectedServiceType, customer?.id)
            }
            options={customerSuspendServiceTypeOptions}
            key={customer?.id}
            {...(customer?.serviceType?.id && {
              defaultValue: customer.serviceType.id,
            })}
            placeholder={"Select Service Type"}
            style={{ width: "180px", marginRight: 10 }}
          ></Select>
        </Row>
        <Row
          style={{
            flexDirection: "column",
            alignItems: "start",
            marginLeft: 8,
          }}
        >
          <label htmlFor="suspendStatus" style={{ margin: "4px 0 2px 4px" }}>
            Suspend Status
          </label>
          <Select
            title="Select a Suspend Status"
            id="suspendStatus"
            onChange={(selectedStatus) =>
              handleChangeStatus(selectedStatus, customer?.id)
            }
            options={customerStatusOptions}
            key={customer?.id}
            {...(customer?.amazonSuspendedApplicantStatus?.id && {
              defaultValue: customer.amazonSuspendedApplicantStatus.id,
            })}
            placeholder={"Select Suspend Status"}
            style={{ width: "180px", marginRight: 10 }}
          ></Select>
        </Row>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={8}>
          <AdditionalPackageHistoryCard id={customer?.id} />
        </Col>
        <Col xs={24} xl={10}>
          <CallHistory customer={customer} />
        </Col>
        <Col xs={24} xl={6}>
          <CustomerInfo
            customer={customer}
            setTriggerUpdate={setTriggerUpdate}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24} xl={9}>
          <Notes
            customerId={customer?.id}
            setTriggerUpdate={setTriggerUpdate}
          />
        </Col>
        <Col xs={24} xl={6}>
          <Attachments
            customer={customer}
            setTriggerUpdate={setTriggerUpdate}
          />
        </Col>
        <Col xs={24} xl={9}>
          <Tasks
            customerName={customer?.fullName}
            customerId={customer?.id}
            setTriggerUpdate={setTriggerUpdate}
            userOptions={userOptions}
          />
        </Col>
      </Row>
    </>
  );
};

export default AmazonSuspendedApplicantDetail;
