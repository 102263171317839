import React, { useEffect, useState } from "react";
import { Avatar, Card, Col, Row, Table, Tooltip } from "antd";
import ReactApexChart from "react-apexcharts";
import "../AcmDashboard/acm-dashboard.css";
import FeedbackService from "../../../services/FeedbackService";
import { getProfilePhotoPath } from "../../../utils";

interface FeedbackCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({
  title,
  dateRange,
  roleName,
}) => {
  const [loading, setLoading] = useState(false);
  const [feedbackData, setFeedbackData] = useState<any>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const colors =["#EEC73B", "#008FFB", "#FF4560"]

  const managerChartOptions = {
    chart: {
      type: "bar" as "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2) + "%";
      },
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "10px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: feedbackData.map((item: any) => item.member),
      labels: {
        rotate: -45,
      },
    },
    yaxis: {
      max: 100,
      tickAmount: 5,
    },
    tooltip: {
      shared: false,
      custom: function ({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }: {
        series: number[][];
        seriesIndex: number;
        dataPointIndex: number;
        w: any;
      }) {
        const questions = [
          "Yaptığınız telefon görüşmesi deneyiminden ne kadar memnun kaldınız?",
          "Görüşme yaptığınız kişi konuya ne kadar hakimdi ve bilgiliydi?",
          "Görüşme yaptığınız kişinin nezaketi ve ilgisi nasıldı?",
        ];
        return `
          <div class="arrow_box" style="max-width: 200px; padding: 10px; text-align: center; white-space: normal;">
            <span style="font-size: 12px;"><b>${
              questions[seriesIndex]
            }</b></span><br />
            <span style="font-size: 12px; color: #000;"><b>${series[
              seriesIndex
            ][dataPointIndex].toFixed(2)}%</b></span>
          </div>`;
      },
    },
    colors,
  };

  const managerChartSeries = [
    {
      name: "Question 1",
      data: feedbackData.map((item: any) => item.soru1 || 0),
    },
    {
      name: "Question 2",
      data: feedbackData.map((item: any) => item.soru2 || 0),
    },
    {
      name: "Question 3",
      data: feedbackData.map((item: any) => item.soru3 || 0),
    },
  ];

  const memberChartOptions = {
    chart: {
      type: "bar" as "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toFixed(2) + "%";
      },
      offsetX: -20,
      offsetY: 0,
      style: {
        fontSize: "10px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: ["You"],
      labels: {
        rotate: -45,
      },
    },
    yaxis: {
      max: 100,
      tickAmount: 5,
    },
    tooltip: {
      shared: false,
      custom: function ({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }: {
        series: number[][];
        seriesIndex: number;
        dataPointIndex: number;
        w: any;
      }) {
        const questions = [
          "Yaptığınız telefon görüşmesi deneyiminden ne kadar memnun kaldınız?",
          "Görüşme yaptığınız kişi konuya ne kadar hakimdi ve bilgiliydi?",
          "Görüşme yaptığınız kişinin nezaketi ve ilgisi nasıldı?",
        ];
        return `
          <div class="arrow_box" style="max-width: 200px; padding: 10px; text-align: center; white-space: normal;">
            <span style="font-size: 12px;"><b>${
              questions[seriesIndex]
            }</b></span><br />
            <span style="font-size: 12px; color: #000;"><b>${series[
              seriesIndex
            ][dataPointIndex].toFixed(2)}%</b></span>
          </div>`;
      },
    },
    colors,
  };

  const memberChartSeries = [
    {
      name: "Question 1",
      data: [
        feedbackData.reduce(
          (acc: number, item: any) => acc + Number(item.satisfactionRating),
          0
        ) / feedbackData.length || 0,
      ],
    },
    {
      name: "Question 2",
      data: [
        feedbackData.reduce(
          (acc: number, item: any) => acc + Number(item.knowledgeRating),
          0
        ) / feedbackData.length || 0,
      ],
    },
    {
      name: "Question 3",
      data: [
        feedbackData.reduce(
          (acc: number, item: any) => acc + Number(item.helpfulnessRating),
          0
        ) / feedbackData.length || 0,
      ],
    },
  ];

  const userId = localStorage.getItem("id");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchFeedbackSummary = async (
    formattedStartDate: string,
    formattedEndDate: string
  ) => {
    try {
      if (roleName === "HI5_ACM_MANAGER") {
        const feedbackResponse = await FeedbackService.getReport(
          formattedStartDate,
          formattedEndDate,
          "HI5_ACM_MEMBER"
        );
        setFeedbackData(feedbackResponse.data);
      } else {
        const feedbackMeResponse = await FeedbackService.getDetailsByUserId(
          Number(userId),
          formattedStartDate,
          formattedEndDate
        );
        setFeedbackData(feedbackMeResponse.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      const formattedStartDate = new Date(dateRange[0]).toISOString();
      const formattedEndDate = new Date(dateRange[1]).toISOString();
      if (formattedEndDate && formattedStartDate) {
        fetchFeedbackSummary(formattedStartDate, formattedEndDate);
        setLoading(true);
      }
    }
  }, [dateRange, roleName]);

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Hi5 ACM Member
        </p>
      ),
      dataIndex: "member",
      key: "member",
      ellipsis: true,
      width: 200,
      sorter: (a: any, b: any) => a?.member?.localeCompare(b?.member),
      defaultSortOrder: "ascend" as "ascend",
      render: (text: string, record: any) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.member}
          </b>
        </Row>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Feedback Count
        </p>
      ),
      dataIndex: "feedbackCount",
      key: "feedbackCount",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.feedbackCount - b?.feedbackCount,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.feedbackCount || 0}
          </p>
        </div>
      ),
    },
    {
      title: (
        <Tooltip
          title={
            "Yaptığınız telefon görüşmesi deneyiminden ne kadar memnun kaldınız?"
          }
          placement="bottom"
        >
          <p
            style={{
              margin: "0px",
              fontWeight: "bold",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            Question 1
          </p>
        </Tooltip>
      ),
      dataIndex: "soru1",
      key: "soru1",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.soru1 - b?.soru1,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.soru1 || 0}
          </p>
        </div>
      ),
    },
    {
      title: (
        <Tooltip
          title={
            "Görüşme yaptığınız kişi konuya ne kadar hakimdi ve bilgiliydi?"
          }
          placement="bottom"
        >
          <p
            style={{
              margin: "0px",
              fontWeight: "bold",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            Question 2
          </p>
        </Tooltip>
      ),
      dataIndex: "soru2",
      key: "soru2",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.soru2 - b?.soru2,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.soru2 || 0}
          </p>
        </div>
      ),
    },
    {
      title: (
        <Tooltip
          title={"Görüşme yaptığınız kişinin nezaketi ve ilgisi nasıldı?"}
          placement="bottom"
        >
          <p
            style={{
              margin: "0px",
              fontWeight: "bold",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            Question 3
          </p>
        </Tooltip>
      ),
      dataIndex: "soru3",
      key: "soru3",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.soru3 - b?.soru3,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.soru3 || 0}
          </p>
        </div>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Average Rating
        </p>
      ),
      dataIndex: "averageRating",
      key: "averageRating",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.averageRating - b?.averageRating,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.averageRating || 0}
          </p>
        </div>
      ),
    },
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24}>
          {roleName === "HI5_ACM_MANAGER" ? (
            <ReactApexChart
              options={managerChartOptions}
              series={managerChartSeries}
              type="bar"
              height={190}
            />
          ) : (
            <ReactApexChart
              options={memberChartOptions}
              series={memberChartSeries}
              type="bar"
              height={190}
            />
          )}
        </Col>
      </Row>
      {roleName === "HI5_ACM_MANAGER" && (
        <Row>
          <Col span={24}>
            <Table
              rowKey={(record) => record?.memberId}
              columns={columns}
              dataSource={feedbackData}
              pagination={false}
              scroll={{ x: "100%", y: 185 }}
              size="small"
              className="dashboard-table"
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default FeedbackCard;
