import { Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CustomerAssignmentHistoryService from "../../../../services/CustomerAssignmentHistoryService";

const MemberBasedTotalSoftwareSuspendedCustomerReport = ({
  memberFlag,
  dateRange,
  teamMember
}: {
  memberFlag: string;
  dateRange: any;
  teamMember: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && memberFlag) {
      const formattedStartDate = new Date(dateRange[0]).toISOString();
      const formattedEndDate = new Date(dateRange[1]).toISOString();
      getOldMemberFlagReport(formattedStartDate, formattedEndDate, memberFlag);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getOldMemberFlagReport = (
    startDate: string,
    endDate: string,
    oldMemberFlag: string
  ) => {
    setLoading(true);
    CustomerAssignmentHistoryService.getOldMemberFlagReport(
      startDate,
      endDate,
      oldMemberFlag
    )
      .then((response) => {
        const groupedData = groupBySuccessTeamMember(response.data);
        setData(groupedData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const groupBySuccessTeamMember = (data: any[]) => {
    const memberMap = new Map();

    data.forEach((item) => {
      const member = item.customer.successTeamMember;
      if (member) {
        const memberKey = `${member.firstName} ${member.lastName}`;
        if (memberMap.has(memberKey)) {
          memberMap.set(memberKey, memberMap.get(memberKey) + 1);
        } else {
          memberMap.set(memberKey, 1);
        }
      }
    });

    return Array.from(memberMap, ([member, customerCount]) => ({
      member,
      customerCount,
    }));
  };

  const columns: ColumnsType<any> = [
    {
      title: teamMember,
      dataIndex: "member",
      key: "member",
      width: 300,
      align: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) => a?.member?.localeCompare(b?.member),
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={record?.member}
          >
            <b>{record?.member}</b>
          </div>
        );
      },
    },
    {
      title: "Total Customer",
      dataIndex: "customerCount",
      key: "customerCount",
      width: 150,
      align: "center",
      sorter: (a: any, b: any) => a?.customerCount - b?.customerCount,
      render: (text: any) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Member Based Total Software Suspended Customer Report</h3>
        <Table
          rowKey={(record) => record?.member}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{ x: "100%" }}
          sticky
          pagination={false}
          summary={(pageData) => {
            const totalCustomerCount = pageData?.reduce(
              (total: number, record: any) => total + record?.customerCount,
              0
            );

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={1} align="left">
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <b>{totalCustomerCount}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default MemberBasedTotalSoftwareSuspendedCustomerReport;
