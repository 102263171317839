import { useParams } from "react-router-dom";
import CustomerService from "../../services/CustomerService";
import { useEffect, useState } from "react";
import { Card, Row, Table, Modal, Col } from "antd";
import DateRange from "../Common/DateRange";
import { ColumnsType } from "antd/es/table";
import moment from "moment";

const CustomerSalesAndProfit = () => {
  const [customerSalesAndProfit, setCustomerSalesAndProfit] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>([]);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    const [startDate, endDate] = dateRange;

    let formattedDateRange;

    if (startDate && endDate) {
      formattedDateRange = `startDate=${startDate}&endDate=${endDate}`;
    }

    if (startDate === undefined || endDate === undefined) {
      return;
    }

    CustomerService.getMasCustomerDashboard(
      Number(id),
      `?${formattedDateRange ?? ""}`
    )
      .then((response) => {
        setCustomerSalesAndProfit(response?.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [dateRange]);

  const onRowClick = (record: any) => {
    const mergedData = record.sale.historical.map(
      (saleItem: any, index: number) => {
        const costItem = record.cost.historical[index];
        const profitItem = record.profit.historical[index];
        const profitRateItem = record.profitRate.historical[index];

        return {
          key: saleItem.key,
          sale: saleItem.value,
          cost: costItem ? costItem.value : null,
          profit: profitItem ? profitItem.value : null,
          profitRate: profitRateItem ? profitRateItem.value : null,
        };
      }
    );

    setModalData(mergedData);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      align: "center",
      ellipsis: true,
      width: 100,
      sorter: (a: any, b: any) => (a.store || "").localeCompare(b.store || ""),
      render: (text: any) =>
        text ? <span>{text.replace(/\s*\(.*?\)\s*/g, "")}</span> : null,
    },
    {
      title: "Total Sales",
      dataIndex: ["sale", "total"],
      key: "totalSales",
      align: "center",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a.sale - b.sale,
      render: (text: any) =>
        text !== null ? (
          <span>
            {`${Number(text).toFixed(2)} `}
            <span style={{ fontWeight: "600" }}>USD</span>
          </span>
        ) : null,
    },
    {
      title: "Total Costs",
      dataIndex: ["cost", "total"],
      key: "totalCosts",
      align: "center",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a.cost - b.cost,
      render: (text: any) =>
        text !== null ? (
          <span>
            {`${Number(text).toFixed(2)} `}
            <span style={{ fontWeight: "600" }}>USD</span>
          </span>
        ) : null,
    },
    {
      title: "Total Profit",
      dataIndex: ["profit", "total"],
      key: "totalProfit",
      align: "center",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a.profit - b.profit,
      render: (text: any) =>
        text !== null ? (
          <span>
            {`${Number(text).toFixed(2)} `}
            <span style={{ fontWeight: "600" }}>USD</span>
          </span>
        ) : null,
    },
    {
      title: "Profit Rate",
      dataIndex: ["profitRate", "total"],
      key: "profitRate",
      align: "center",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a.profitRate - b.profitRate,
      render: (text: any) =>
        text !== null ? <b>{`${Number(text)}% `}</b> : null,
    },
  ];

  const modalColumns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "key",
      key: "date",
      align: "center",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) =>
        new Date(a.key).getTime() - new Date(b.key).getTime(),
      render: (text: any) =>
        text ? <span>{moment(text).format('DD/MM/YYYY')}</span> : null,
    },
    {
      title: "Sale Value",
      dataIndex: "sale",
      key: "sale",
      align: "center",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a.sale - b.sale,
      render: (text: any) =>
        text !== null ? (
          <span>
            {`${Number(text).toFixed(2)} `}
            <span style={{ fontWeight: "600" }}>USD</span>
          </span>
        ) : null,
    },
    {
      title: "Cost Value",
      dataIndex: "cost",
      key: "cost",
      align: "center",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a.cost - b.cost,
      render: (text: any) =>
        text !== null ? (
          <span>
            {`${Number(text).toFixed(2)} `}
            <span style={{ fontWeight: "600" }}>USD</span>
          </span>
        ) : null,
    },
    {
      title: "Profit Value",
      dataIndex: "profit",
      key: "profit",
      align: "center",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a.profit - b.profit,
      render: (text: any) =>
        text !== null ? (
          <span>
            {`${Number(text).toFixed(2)} `}
            <span style={{ fontWeight: "600" }}>USD</span>
          </span>
        ) : null,
    },
    {
      title: "Profit Rate",
      dataIndex: "profitRate",
      key: "profitRate",
      align: "center",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a.profitRate - b.profitRate,
      render: (text: any) =>
        text !== null ? <b>{`${Number(text)}% `}</b> : null,
    },
  ];

  return (
    <>
      <Card bordered={false} className={"sales-card"}>
        <Row align={"middle"} justify={"space-between"}>
          <Col>
            <p className="title">Sales & Profit</p>
          </Col>
          <Col>
            <DateRange onChangeDateRange={setDateRange} thisMonth={true} />
          </Col>
        </Row>
        <Row style={{ marginTop: "24px", maxHeight: "195px" }}>
          <Col span={24}>
            <Table
              rowKey={(record) => record.key}
              loading={loading}
              columns={columns}
              dataSource={customerSalesAndProfit}
              pagination={false}
              scroll={{ x: "100%", y: 160 }}
              onRow={(record) => ({
                onClick: () => onRowClick(record),
                style: { cursor: 'pointer' },
              })}
            />
          </Col>
        </Row>
      </Card>
      <Modal
        title="Historical Data"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }} 
      >
        <Table
          rowKey={(record) => record.key}
          columns={modalColumns}
          dataSource={modalData}
          sticky
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </Modal>
    </>
  );
};

export default CustomerSalesAndProfit;
