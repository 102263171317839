import { Col, DatePicker, Row, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CustomerAssignmentHistoryService from "../../../../services/CustomerAssignmentHistoryService";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Text } = Typography;

const MemberBasedTotalCustomerReport = ({
  memberFlag,
  teamMember,
}: {
  memberFlag: string;
  teamMember: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
    dayjs().subtract(1, "day").local()
  );

  useEffect(() => {
    if (memberFlag && selectedDate) {
      getMemberBasedTotalCustomerReport(
        memberFlag,
        selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      );
    }
    // eslint-disable-next-line
  }, [memberFlag]);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setSelectedDate(date.local());
      getMemberBasedTotalCustomerReport(
        memberFlag,
        date.local().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      );
    }
  };

  const getMemberBasedTotalCustomerReport = (
    memberFlag: string,
    endDate: string
  ) => {
    setLoading(true);
    CustomerAssignmentHistoryService.getMemberBasedTotalCustomerReport(
      memberFlag,
      endDate
    )
      .then((response) => {
        setData(response.data.reportList);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: teamMember,
      dataIndex: "member",
      key: "member",
      width: 300,
      align: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) =>
        a?.member?.firstName.localeCompare(b?.member?.firstName),
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.member?.firstName} ${record?.member?.lastName}`}
          >
            <b>
              {record?.member?.firstName} {record?.member?.lastName}
            </b>
          </div>
        );
      },
    },
    {
      title: "Total Customer",
      dataIndex: "customerCount",
      key: "customerCount",
      width: 150,
      align: "center",
      sorter: (a: any, b: any) => a?.customerCount - b?.customerCount,
      render: (text: any) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Member Based Total Customer Report</h3>
        <Col span={24} style={{ margin: "8px 0 8px 8px" }}>
          <DatePicker
            format="DD/MM/YYYY"
            value={selectedDate}
            onChange={handleDateChange}
            defaultValue={dayjs().subtract(1, "day").local()}
          />
        </Col>
        <Text style={{ margin: "0 0 5px 0" }} type="warning">
          ** Member based customer report shows the data of 12.30 noon every
          day.
        </Text>
        <Table
          rowKey={(record) => record?.member?.id}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{ x: "100%" }}
          sticky
          pagination={false}
          summary={(pageData) => {
            const totalCustomerCount = pageData?.reduce(
              (total: number, record: any) => total + record?.customerCount,
              0
            );

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={1} align="left">
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <b>{totalCustomerCount}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default MemberBasedTotalCustomerReport;
