import { Col, Row } from "antd";
import "./dashboard.css";
import { useEffect, useState } from "react";
import { HeaderBar } from "../components/Dashboard/HeaderBar";
import InfoCard from "../components/Dashboard/AcmDashboard/InfoCard";
import DashboardService from "../services/DashboardService";
import { MasCustomerList } from "../components/Dashboard/MasDashboard/MasCustomerList";
import { motion } from "framer-motion";
import SalesCard from "../components/Dashboard/MasDashboard/SalesCard";

export const MasDashboard = () => {
  const [dateRange, setDateRange] = useState<any>([]);
  const [summary, setSummary] = useState<any>();
  const [activeCustomersCount, setActiveCustomersCount] = useState<any>(0);
  const [feedbackRating, setFeedbackRating] = useState<any>(0);
  const [oneamzOrderCount, setOneamzOrderCount] = useState<any>(0);
  const [totalOrderCount, setTotalOrderCount] = useState<any>(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // const [startDate, endDate] = dateRange;

    // let formattedDateRange;

    // let formattedDateRange2;
    // if (startDate && endDate) {
    //   formattedDateRange = `startDate=${startDate}T00:00:00&endDate=${endDate}T23:59:59`;
    //   formattedDateRange2 = `startDate=${startDate}&endDate=${endDate}`;
    // }

    // if (startDate === undefined || endDate === undefined) {
    //   return;
    // }

    // DashboardService.masSummary(`?${formattedDateRange ?? ""}`)
    DashboardService.masSummary()
      .then((response) => {
        setSummary(response?.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      style={{ background: "#F0F0F0", padding: "30px" }}
      className="acm-dashboard"
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
        className="acm-dashboard"
      >
        <HeaderBar setDateRange={setDateRange} thisMonth={true} />

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col sm={24} md={10}>
            <SalesCard title="Sales Chart" summary={summary} />
          </Col>
          <Col sm={24} md={14}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <InfoCard
                  title="Active"
                  description="Customers"
                  count={activeCustomersCount}
                  dateRange={dateRange}
                  loading={loading}
                />
              </Col>
              <Col xs={24} sm={12}>
                <InfoCard
                  title="OneAMZ Order Count"
                  description="Customers"
                  count={oneamzOrderCount}
                  dateRange={dateRange}
                  loading={loading}
                />
              </Col>
              <Col xs={24} sm={12} className="bottom-cards">
                <InfoCard
                  title="Order Count"
                  description="Total"
                  count={totalOrderCount}
                  dateRange={dateRange}
                  loading={loading}
                />
              </Col>
              <Col xs={24} sm={12} className="bottom-cards">
                <InfoCard
                  title="Feedback Ratings"
                  description="Average"
                  count={feedbackRating}
                  dateRange={dateRange}
                  loading={loading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <MasCustomerList />
      </motion.div>
    </div>
  );
};
