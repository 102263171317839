import { Avatar, Card, Col, Row, Table } from "antd";
import { getProfilePhotoPath } from "../../../utils";
import { ColumnsType } from "antd/es/table";

interface SalesCardProps {
  title: string;
  summary: any;
}

const SalesCard: React.FC<SalesCardProps> = ({ title, summary }) => {
  const roleName = localStorage.getItem("roleName");

  const getPackageCounts = (customerList: any) => {
    let autoPilotCount = 0;
    let preAutoPilotCount = 0;
    const today = new Date();
  
    customerList?.forEach((customer: any) => {
      customer.additionalPackageHistoryList?.forEach((packageHistory: any) => {
        const endDate = new Date(packageHistory.endDate);
  
        if (endDate > today) {
          if (packageHistory.packageId === 3) {
            autoPilotCount++;
          } else if (packageHistory.packageId === 6) {
            preAutoPilotCount++;
          }
        }
      });
    });
  
    return { autoPilotCount, preAutoPilotCount };
  };

  const tableData =
    summary?.map((item: any) => {
      const { autoPilotCount, preAutoPilotCount } = getPackageCounts(
        item.customerList || []
      );
      return {
        key: item.user?.id,
        user: item.user,
        autoPilot: autoPilotCount,
        preAutoPilot: preAutoPilotCount,
      };
    }) || [];

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      width: 200,
      render: (text: string, record: any) => (
        <Row
          align="middle"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(record?.user?.profilePhoto || 1)}
          />
          <p
            style={{
              margin: "0px",
              padding: "0px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {record?.user?.firstName} {record?.user?.lastName}
          </p>
        </Row>
      ),
    },
    {
      title: "AutoPilot",
      dataIndex: "autoPilot",
      key: "autoPilot",
      width: 100,
      align: "center" as "center",
    },
    {
      title: "Pre-AutoPilot",
      dataIndex: "preAutoPilot",
      key: "preAutoPilot",
      width: 150,
      align: "center" as "center",
    },
  ];

  return (
    <Card bordered={false} className="sales-card">
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      {roleName === "MAS_TEAM_MANAGER" && (
        <p className="description" style={{ marginBottom: "-20px" }}>
          {""} Customers
        </p>
      )}
      {roleName === "MAS_TEAM_MANAGER" && (
        <Row>
          <Col span={24}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{ x: "100%", y: 150 }}
              size="small"
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default SalesCard;
