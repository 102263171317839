import http from "../http-common";

const getCustomerAssignmentHistory = () => {
    return http.get<any>("/customer-assignment-history");
}

const getStatusBasedOldMemberFlagReport = (startDate: string, endDate: string, oldMemberFlag: string) => {
    return http.get<any>(`/customer-assignment-history/status-based-old-member-flag-report?startDate=${startDate}&endDate=${endDate}&oldMemberFlag=${oldMemberFlag}`);
}

const getStatusBasedNewMemberFlagReport = (startDate: string, endDate: string, newMemberFlag: string) => {
    return http.get<any>(`/customer-assignment-history/status-based-new-member-flag-report?startDate=${startDate}&endDate=${endDate}&newMemberFlag=${newMemberFlag}`);
}

const getMemberBasedTotalCustomerReport = (memberFlag: string, date: string) => {
    return http.get<any>(`/customers/member-based-total-customer-report?memberFlag=${memberFlag}&date=${date}`);
}

const getOldMemberFlagReport = (startDate: string, endDate: string, oldMemberFlag: string) => {
    return http.get<any>(`/customer-assignment-history/old-member-flag-report?startDate=${startDate}&endDate=${endDate}&oldMemberFlag=${oldMemberFlag}`);
}

const getNewMemberFlagReport = (startDate: string, endDate: string, newMemberFlag: string) => {
    return http.get<any>(`/customer-assignment-history/new-member-flag-report?startDate=${startDate}&endDate=${endDate}&newMemberFlag=${newMemberFlag}`);
}

const updateAssignmentReasonStatus = (id: number, statusId: number) => {
    return http.put<any>(`customer-assignment-history/${id}/assignment-reason-status`, { statusId });
}

const CustomerAssignmentHistoryService = {
    getCustomerAssignmentHistory,
    getStatusBasedOldMemberFlagReport,
    getStatusBasedNewMemberFlagReport,
    getMemberBasedTotalCustomerReport,
    getOldMemberFlagReport,
    getNewMemberFlagReport,
    updateAssignmentReasonStatus
};

export default CustomerAssignmentHistoryService;