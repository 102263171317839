import { useEffect, useState } from "react";
import CustomerAssignmentHistoryService from "../services/CustomerAssignmentHistoryService";
import { ColumnsType } from "antd/es/table";
import { CopyClipboard } from "../components/General/CopyClipboard";
import { StartCall } from "../components/General/StartCall";
import { formatDateString, openNotification } from "../utils";
import { Card, Col, Input, Row, Select, Skeleton } from "antd";
import { Table } from "antd/lib";
import StatusService from "../services/StatusService";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";

const DropoutFeedbackList = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [reasonForDropoutStatusOptions, setReasonForDropoutStatusOptions] =
    useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const getCustomerAssignmentHistoryList = () => {
    setLoading(true);
    CustomerAssignmentHistoryService.getCustomerAssignmentHistory()
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getReasonForDropoutStatus = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=CUSTOMER_ASSIGNMENT_REASON_STATUS")
      .then((response: any) => {
        if (response.data) {
          let data = response.data;
          data.forEach((item: any) => {
            if (item.parent === null) {
              mainStatuses.push(item);
            } else {
              subStatuses.push(item);
            }
          });

          let processedData = mainStatuses.map((mainStatus: any) => {
            return {
              ...mainStatus,
              subStatuses: subStatuses.filter(
                (subStatus: any) => subStatus.parent.id === mainStatus.id
              ),
            };
          });
          setReasonForDropoutStatusOptions(processedData);
          setLoading(false);
        }
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerAssignmentHistoryList();
    if (reasonForDropoutStatusOptions.length === 0) {
      getReasonForDropoutStatus();
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeReasonForDropoutStatus = (status: any, id: any) => {
    setLoading(true);
    CustomerAssignmentHistoryService.updateAssignmentReasonStatus(id, status)
      .then((response: any) => {
        openNotification("success", "Success", "Changed reason for dropout");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    if (value === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter(
        (item: any) =>
          item.customer.fullName.toLowerCase().includes(value) ||
          item.customer.email.toLowerCase().includes(value) ||
          item.customer.phone.toLowerCase().includes(value)
      );
      setFilteredData(filtered);
    }
  };

  const clearInput = () => {
    setSearchText("");
    setFilteredData(data);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Customer",
      dataIndex: ["customer", "fullName"],
      key: "fullName",
      fixed: "left",
      sorter: (a, b) =>
        a?.customer?.fullName?.localeCompare(b?.customer?.fullName),
      width: 240,
      render: (
        text: string,
        record: {
          customer: {
            email: string;
            phone: string;
          };
        }
      ) => (
        <>
          <div>
            <CopyClipboard text={text} />{" "}
          </div>
          <span>
            <CopyClipboard text={record?.customer?.email} />{" "}
          </span>
          <br />
          <span>
            <span>{record?.customer?.phone}</span>{" "}
            <StartCall text={record?.customer?.phone} />
          </span>
        </>
      ),
    },
    {
      title: "Transition Date",
      dataIndex: "assignmentDate",
      key: "assignmentDate",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => a?.assignmentDate?.localeCompare(b?.assignmentDate),
      width: 110,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {formatDateString(text)}
        </p>
      ),
    },
    {
      title: "Old Location",
      dataIndex: "oldMemberFlag",
      key: "oldMemberFlag",
      width: 200,
      sorter: (a, b) => {
        const formatText = (text: string) =>
          text
            ?.replace(/_/g, " ")
            ?.toLowerCase()
            ?.replace(/\b\w/g, (char) => char.toUpperCase());

        return formatText(a?.oldMemberFlag)?.localeCompare(
          formatText(b?.oldMemberFlag)
        );
      },
      render: (text: string) => {
        const formattedText = text
          ?.replace(/_/g, " ")
          ?.toLowerCase()
          ?.replace(/\b\w/g, (char) => char.toUpperCase());

        return <span>{formattedText}</span>;
      },
    },
    {
      title: "New Location",
      dataIndex: "newMemberFlag",
      key: "newMemberFlag",
      width: 200,
      sorter: (a, b) => {
        const formatText = (text: string) =>
          text
            ?.replace(/_/g, " ")
            ?.toLowerCase()
            ?.replace(/\b\w/g, (char) => char.toUpperCase());

        return formatText(a?.newMemberFlag)?.localeCompare(
          formatText(b?.newMemberFlag)
        );
      },
      render: (text: string) => {
        const formattedText = text
          ?.replace(/_/g, " ")
          ?.toLowerCase()
          ?.replace(/\b\w/g, (char) => char.toUpperCase());

        return <span>{formattedText}</span>;
      },
    },
    {
      title: "Reason for Dropout",
      dataIndex: ["assignmentReasonStatus", "name"],
      key: "name",
      width: 200,
      render: (_: any, row: any) => {
        return (
          <Select
            showSearch
            filterOption={(input, option) =>
              option && option.label && typeof option.label === "string"
                ? option.label.toLowerCase().includes(input.toLowerCase())
                : false
            }
            onChange={(selectedStatus) =>
              handleChangeReasonForDropoutStatus(selectedStatus, row.id)
            }
            key={row.id}
            defaultValue={
              row.assignmentReasonStatus && row.assignmentReasonStatus.id
            }
            placeholder={"Select"}
            style={{ width: "180px" }}
          >
            {reasonForDropoutStatusOptions.map((mainStatus) => (
              <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                {mainStatus.subStatuses.map((subStatus: any) => (
                  <Select.Option key={subStatus.id} value={subStatus.id}>
                    {subStatus.name}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        );
      },
    },
  ];

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Dropout Feedback List
            </div>
            <div className="customer-card-info">
              <b>{filteredData?.length} </b>Customers
            </div>
          </div>
        }
        extra={
          <Row
            style={{ minWidth: 400, display: "flex", justifyContent: "end" }}
            gutter={[10, 10]}
          >
            <Col xs={12}>
              <Input
                prefix={<SearchOutlined />}
                suffix={
                  searchText && (
                    <CloseCircleOutlined
                      onClick={clearInput}
                      style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
                    />
                  )
                }
                placeholder="Search..."
                value={searchText}
                onChange={onSearchChange}
                style={{ maxWidth: 200 }}
              />
            </Col>
          </Row>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        {filteredData.length > 0 ? (
          <Table
            rowKey={(record) => record?.id}
            scroll={{ x: "100%" }}
            sticky
            columns={columns}
            dataSource={filteredData}
            pagination={false}
          />
        ) : (
          <Skeleton
            active
            paragraph={{ rows: 10 }}
            style={{ marginTop: "10px" }}
          />
        )}
      </Card>
    </>
  );
};

export default DropoutFeedbackList;
