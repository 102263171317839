import React, { useEffect, useState } from "react";
import { Card, Col, Row, Space } from "antd";
import "../AcmDashboard/acm-dashboard.css";
import DashboardService from "../../../services/DashboardService";

interface TotalCustomersCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const TotalCustomersCard: React.FC<TotalCustomersCardProps> = ({
  title,
  dateRange,
  roleName,
}) => {
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  // eslint-disable-next-line
  // const [options, setOptions] = useState<any>({
  //   chart: {
  //     type: "donut",
  //   },
  //   stroke: {
  //     lineCap: "round",
  //   },
  //   plotOptions: {
  //     radialBar: {
  //       track: {
  //         background: "#f4f4f4",
  //       },
  //       dataLabels: {
  //         name: {
  //           show: true,
  //           fontSize: "15px",
  //         },
  //         value: {
  //           fontWeight: 600,
  //         },
  //       },
  //     },
  //   },
  //   labels: ["Commission"],
  //   colors: ["#EEC73B"],
  //   tooltip: {
  //     enabled: true,
  //     theme: "dark",
  //     y: {
  //       formatter: function (val: number) {
  //         return val.toFixed(2) + "%";
  //       },
  //     },
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       options: {
  //         plotOptions: {
  //           radialBar: {
  //             dataLabels: {
  //               name: {
  //                 fontSize: "12px",
  //               },
  //               value: {
  //                 fontSize: "12px",
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       options: {
  //         plotOptions: {
  //           radialBar: {
  //             dataLabels: {
  //               name: {
  //                 fontSize: "15px",
  //               },
  //               value: {
  //                 fontSize: "15px",
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //     // {
  //     //   breakpoint: 576,
  //     //   options: {
  //     //     plotOptions: {
  //     //       radialBar: {
  //     //         dataLabels: {
  //     //           name: {
  //     //             fontSize: "12px",
  //     //           },
  //     //           value: {
  //     //             fontSize: "12px",
  //     //           },
  //     //         },
  //     //       },
  //     //     },
  //     //   },
  //     // },
  //     {
  //       breakpoint: 480,
  //       options: {
  //         plotOptions: {
  //           radialBar: {
  //             dataLabels: {
  //               name: {
  //                 fontSize: "12px",
  //               },
  //               value: {
  //                 fontSize: "12px",
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 380,
  //       options: {
  //         chart: {
  //           width: 100,
  //         },
  //         legend: {
  //           position: "bottom",
  //         },
  //       },
  //     },
  //   ],
  // });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const fetchSalesSummary = async (formattedDateRange: string) => {
    try {
      if (roleName === "HI5_ACM_MANAGER") {
        const totalCustomersResponse = await DashboardService.getSalesSummaryHi5Acm(
          `${formattedDateRange}`
        );
        
      } else {
        const totalCustomersMeResponse =
          await DashboardService.getSalesSummaryMeHi5Acm(
            `${formattedDateRange}`
          );
        
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      const formattedDateRange = `?startDate=${dateRange[0]}&endDate=${dateRange[1]}`;
      if (formattedDateRange) {
        // setLoading(true);
        // fetchSalesSummary(formattedDateRange);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, roleName]);

  const columns = [{}]

  // const columns = [
  //   {
  //     title: (
  //       <p
  //         style={{
  //           margin: "0px",
  //           fontWeight: "bold",
  //           fontSize:
  //             windowWidth < 480
  //               ? "12px"
  //               : windowWidth < 768
  //               ? "14px"
  //               : windowWidth < 992
  //               ? "12px"
  //               : "14px",
  //         }}
  //       >
  //         Hi5 ACM Member
  //       </p>
  //     ),
  //     dataIndex: "firstName",
  //     key: "firstName",
  //     ellipsis: true,
  //     width: 200,
  //     render: (text: string, record: any) => (
  //       <Row
  //         align={"middle"}
  //         style={{
  //           overflow: "hidden",
  //           textOverflow: "ellipsis",
  //           whiteSpace: "nowrap",
  //           padding: "0px 0px",
  //         }}
  //       >
  //         <Avatar
  //           style={{ marginRight: 5 }}
  //           size={30}
  //           src={getProfilePhotoPath(record?.user?.profilePhoto || 1)}
  //         />
  //         <b
  //           style={{
  //             margin: "0px",
  //             whiteSpace: "nowrap",
  //             textOverflow: "ellipsis",
  //             overflow: "hidden",
  //             fontSize:
  //               windowWidth < 480
  //                 ? "12px"
  //                 : windowWidth < 768
  //                 ? "14px"
  //                 : windowWidth < 992
  //                 ? "12px"
  //                 : "14px",
  //           }}
  //         >
  //           {record?.user?.firstName} {record?.user?.lastName}
  //         </b>
  //       </Row>
  //     ),
  //   },
  //   {
  //     title: (
  //       <p
  //         style={{
  //           margin: "0px",
  //           fontWeight: "bold",
  //           fontSize:
  //             windowWidth < 480
  //               ? "12px"
  //               : windowWidth < 768
  //               ? "14px"
  //               : windowWidth < 992
  //               ? "12px"
  //               : "14px",
  //           textAlign: "right",
  //         }}
  //       >
  //         Total Sales
  //       </p>
  //     ),
  //     dataIndex: "salesPackageCount",
  //     key: "salesPackageCount",
  //     width: 100,
  //     align: "right" as const,
  //     render: (text: string, record: any, index: number) => (
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "flex-end",
  //           alignItems: "center",
  //         }}
  //       >
  //         {index < 3 && (
  //           <img
  //             src={
  //               index === 0
  //                 ? goldIcon
  //                 : index === 1
  //                 ? silverIcon
  //                 : index === 2
  //                 ? bronzIcon
  //                 : ""
  //             }
  //             alt="icon"
  //             style={{ marginRight: 16 }}
  //           />
  //         )}
  //         <p
  //           style={{
  //             margin: "0px 10px 0px 0px",
  //             whiteSpace: "nowrap",
  //             width: "12px",
  //             textAlign: "right",
  //             fontWeight: "bold",
  //             fontSize:
  //               windowWidth < 480
  //                 ? "12px"
  //                 : windowWidth < 768
  //                 ? "14px"
  //                 : windowWidth < 992
  //                 ? "12px"
  //                 : "14px",
  //           }}
  //         >
  //           {text}
  //         </p>
  //       </div>
  //     ),
  //   },
  // ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={13} md={13}>
          {/* <ReactApexChart
            options={options}
            series={
              roleName === "HI5_ACM_MANAGER" ? [salesRatio] : [salesRatioMe]
            }
            type="radialBar"
            height={200}
          /> */}
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Row
            align="middle"
            justify="center"
            style={{
              margin:
                windowWidth < 480
                  ? "-30px 0px 10px 0px"
                  : windowWidth < 576
                  ? "-20px 0px 10px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Space direction="vertical" size={0}>
              {/* <Row gutter={[3, 3]}>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "left" : "left",
                  }}
                >
                  <Badge
                    color="#f4f4f4"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Total:{" "}
                        <b>
                          {roleName === "HI5_ACM_MANAGER" ? total : totalMe}
                        </b>{" "}
                        sales
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "right" : "left",
                  }}
                >
                  <Badge
                    color="#EEC73B"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Commission:{" "}
                        <b>
                          $
                          {roleName === "HI5_ACM_MANAGER"
                            ? totalCommission
                            : totalCommissionMe}
                        </b>
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "center" : "left",
                  }}
                >
                  <Badge
                    color="#0086FF"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Turnover:{" "}
                        <b>
                          $
                          {roleName === "HI5_ACM_MANAGER"
                            ? totalTurnover
                            : totalTurnoverMe}
                        </b>
                      </span>
                    }
                  />
                </Col>
              </Row> */}
            </Space>
          </Row>
        </Col>
      </Row>
      {roleName === "HI5_ACM_MANAGER" && (
        <>
          <Row>
            <Col span={24}>
              {/* <Table
                rowKey={(record) => record?.id}
                columns={columns}
                dataSource={allTeamUsers}
                pagination={false}
                scroll={{ x: "100%", y: 185 }}
                size="small"
                className="dashboard-table"
              /> */}
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default TotalCustomersCard;
