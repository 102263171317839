import { Breadcrumb, Card, Col, Row, Typography } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { ReportsRouter } from "../../ReportsRouter";
import DateRange from "../../../Common/DateRange";
import { useState } from "react";
import MemberBasedTotalCustomerReport from "./MemberBasedTotalCustomerReport";
import MemberFlagReport from "./MemberFlagReport";
import ReasonForDropoutReport from "./ReasonForDropoutReport";
import SalesLogReport from "./SalesLogReport";
import MemberBasedTotalSoftwareSuspendedCustomerReport from "./MemberBasedTotalSoftwareSuspendedCustomerReport";

const { Title } = Typography;

const SummaryReport = () => {
  const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);
  const location = useLocation();

  const getTeamMemberAndFlags = () => {
    if (location.pathname.includes("/stm-reports/summary-report")) {
      return {
        teamMember: "Success Team Member",
        memberFlag: "SUCCESS_TEAM",
      };
    } else if (location.pathname.includes("/acm-reports/summary-report")) {
      return {
        teamMember: "Account Manager",
        memberFlag: "ACCOUNT_MANAGER",
      };
    } else if (location.pathname.includes("/hi5-acm-reports/summary-report")) {
      return {
        teamMember: "Hi5 Account Manager",
        memberFlag: "HI5_ACM_MEMBER",
      };
    } else
      return {
        teamMember: "",
        memberFlag: "",
      };
  };

  const { teamMember, memberFlag } = getTeamMemberAndFlags();

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Summary Report",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Summary Report
              </div>
            </div>
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
          </Row>
        }
        bordered
        style={{ marginTop: "30px" }}
      >
        <MemberBasedTotalCustomerReport
          memberFlag={memberFlag}
          teamMember={teamMember}
        />
        <MemberFlagReport memberFlag={memberFlag} dateRange={dateRange} />
        <ReasonForDropoutReport memberFlag={memberFlag} dateRange={dateRange} />
        {location.pathname.includes("/stm-reports/summary-report") && (
          <ReasonForDropoutReport memberFlag={"SUCCESS_TEAM_SOFTWARE_SUSPEND"} dateRange={dateRange} />
        )}
        <SalesLogReport
          memberFlag={memberFlag}
          dateRange={dateRange}
          teamMember={teamMember}
        />
        {location.pathname.includes("/stm-reports/summary-report") && (
          <MemberBasedTotalSoftwareSuspendedCustomerReport
            memberFlag={"SUCCESS_TEAM_SOFTWARE_SUSPEND"}
            dateRange={dateRange}
            teamMember={teamMember}
          />
        )}
      </Card>
    </>
  );
};

export default SummaryReport;
