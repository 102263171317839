import { Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CustomerAssignmentHistoryService from "../../../../services/CustomerAssignmentHistoryService";

const MemberFlagReport = ({
  dateRange,
  memberFlag,
}: {
  dateRange: any;
  memberFlag: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [oldMemberFlagData, setOldMemberFlagData] = useState<any>([]);
  const [newMemberFlagData, setNewMemberFlagData] = useState<any>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && memberFlag) {
      const formattedStartDate = new Date(dateRange[0]).toISOString();
      const formattedEndDate = new Date(dateRange[1]).toISOString();
      getOldMemberFlagReport(formattedStartDate, formattedEndDate, memberFlag);
      getNewMemberFlagReport(formattedStartDate, formattedEndDate, memberFlag);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getOldMemberFlagReport = (
    startDate: string,
    endDate: string,
    oldMemberFlag: string
  ) => {
    setLoading(true);
    CustomerAssignmentHistoryService.getOldMemberFlagReport(
      startDate,
      endDate,
      oldMemberFlag
    )
      .then((response) => {
        setOldMemberFlagData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNewMemberFlagReport = (
    startDate: string,
    endDate: string,
    newMemberFlag: string
  ) => {
    setLoading(true);
    CustomerAssignmentHistoryService.getNewMemberFlagReport(
      startDate,
      endDate,
      newMemberFlag
    )
      .then((response) => {
        setNewMemberFlagData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (oldMemberFlagData.length || newMemberFlagData.length) {
      const newDataSource = [
        {
          key: "1",
          leavingCount: oldMemberFlagData.length,
          joiningCount: newMemberFlagData.length,
        },
      ];
      setDataSource(newDataSource);
    }
  }, [oldMemberFlagData, newMemberFlagData]);

  const columns: ColumnsType<any> = [
    {
      title: "Customer Count Leaving",
      dataIndex: "leavingCount",
      align: "center",
      render: (text) => <b>{text}</b>,
    },
    {
      title: "Customer Count Joining",
      dataIndex: "joiningCount",
      align: "center",
      render: (text) => <b>{text}</b>,
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>
          Customer Count Leaving & Joining Report
        </h3>
        <Table
          rowKey={(record) => record?.key}
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: "100%" }}
          sticky
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default MemberFlagReport;
